define('blockxblock/helpers/try-showing', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.tryShowing = tryShowing;
  function tryShowing(_ref) {
    var value = _ref[0];

    return value !== null && value !== undefined && value !== "" ? value : Ember.String.htmlSafe('<span class="missing-data">Data Unavailable</span>');
  }

  exports.default = Ember.Helper.helper(tryShowing);
});