define("blockxblock/utils/get-timely-snapshot", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getTimelySnapshot;
  /*
   * This function iterates through snapshots to find if there is
   * a snapshot with a date before the filter's year and month.
   */

  function getTimelySnapshot(filter) {
    var snapshots = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    var filterDate = dtoi(filter.year, filter.month);
    var i = 0;

    if (snapshots.length > 1) {
      snapshots.sort(function (a, b) {
        return a.date < b.date;
      });
    }

    snapshots.some(function (snapshot) {
      var date = new Date(snapshot.date);
      var snapshotDate = dtoi(date.getFullYear(), date.getMonth());
      return snapshotDate < filterDate || !++i;
    });

    return snapshots[i] || null;
  }

  // We only care about year/month composites since we want
  // to capture all days throughout the entire month specified.
  function dtoi(year, month) {
    return parseInt(year) * 100 + parseInt(month);
  }
});