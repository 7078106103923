define('blockxblock/services/current-city', ['exports', 'blockxblock/utils/apply-filter-to', 'blockxblock/utils/arrayify', 'blockxblock/models/place', 'blockxblock/models/investment', 'blockxblock/models/parcel'], function (exports, _applyFilterTo, _arrayify, _place, _investment, _parcel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PARCEL_PARAMS = ['landuseTypes', 'GFVacancyStatuses', 'UFVacancyStatuses', 'ownershipTypes', 'tenureStatuses', 'yearBuiltMin', 'yearBuiltMax', 'isEngagedOwner'];
  var INVESTMENT_PARAMS = ['is_tdi_influenced', 'investmentTypesArray.[]', 'valueMin', 'valueMax', 'investmentStatuses', 'investmentSources', 'investments_fake_open_or_closed'];
  var FEATURE_PARAMS = ['assetTypesArray.[]', 'activating', 'is_open', 'is_proposed', 'is_in_progress', 'is_employer', 'tdi_asset', 'engaged_owner', 'community_hub'];
  [INVESTMENT_PARAMS, FEATURE_PARAMS, PARCEL_PARAMS].forEach(function (paramsList) {
    return paramsList.push('timelineDate');
  });

  var SOUTHWICK_LATITUDE = 42.1;
  var SOUTHWICK_LONGITUDE = -71.6;
  var DEFAULT_ZOOM = 17;

  exports.default = Ember.Service.extend({
    mapInstance: null,
    latitude: SOUTHWICK_LATITUDE,
    longitude: SOUTHWICK_LONGITUDE,
    zoom: DEFAULT_ZOOM,
    setCity: function setCity(city) {
      var _this = this;

      this.set('city', city);
      var latitude = city.get('latitude');
      var longitude = city.get('longitude');
      Ember.run.next(this, function () {
        _this.setProperties({
          latitude: latitude,
          longitude: longitude
        });
      });
    },
    setAllInvestments: function setAllInvestments(investments) {
      this.set('all_investments', investments);
    },

    city: '',
    all_investments: null,

    startYear: 2013,
    timelineYear: String(new Date().getFullYear()),
    timelineMonth: function () {
      var month = new Date().getMonth() + 1;
      return String(month >= 10 ? month : '0' + month);
    }(),
    timelineDate: Ember.computed('timelineYear', 'timelineMonth', function () {
      return {
        year: this.get('timelineYear'),
        month: this.get('timelineMonth')
      };
    }),

    showClosedPlaces: false,

    choroplethLayer: 'Available Spaces',
    parcelChoroplethSets: _parcel.PARCEL_MAP_CONFIG.mapBy('setName'),
    parcelChoroplethConfig: _parcel.PARCEL_MAP_CONFIG,

    landuseTypes: '',
    landuseTypesArray: Ember.computed('landuseTypes', (0, _arrayify.default)('landuseTypes', '|')),
    landuseTypesOptions: _parcel.PARCEL_TYPES,

    ownershipTypes: '',
    ownershipTypesArray: Ember.computed('ownershipTypes', (0, _arrayify.default)('ownershipTypes', '|')),
    ownershipTypesOptions: _parcel.PARCEL_OWNERSHIP_TYPES,

    GFVacancyStatuses: '',
    GFVacancyStatusesArray: Ember.computed('GFVacancyStatuses', (0, _arrayify.default)('GFVacancyStatuses', '|')),
    GFVacancyStatusesOptions: _parcel.GFVACANCY_STATUSES,

    UFVacancyStatuses: '',
    UFVacancyStatusesArray: Ember.computed('UFVacancyStatuses', (0, _arrayify.default)('UFVacancyStatuses', '|')),
    UFVacancyStatusesOptions: _parcel.UFVACANCY_STATUSES,

    tenureStatuses: 'for_sale|for_lease',
    tenureStatusesArray: Ember.computed('tenureStatuses', (0, _arrayify.default)('tenureStatuses', '|')),
    tenureStatusesOptions: _parcel.TENURE_STATUSES,

    showInvestments: true,
    showPlaces: true,
    showParcels: false,

    is_open: true,
    activating: true,
    is_tdi_influenced: true,

    assetTypes: [].concat(_place.FEATURE_TYPES, ['District-wide']).join('|'),
    assetTypesArray: Ember.computed('assetTypes', (0, _arrayify.default)('assetTypes', '|')),
    assetTypeOptions: _place.FEATURE_TYPES,

    statusTypesArray: Ember.computed('is_open', 'is_proposed', 'is_in_progress', function () {
      var _getProperties = this.getProperties('is_open', 'is_proposed', 'is_in_progress'),
          is_open = _getProperties.is_open,
          is_proposed = _getProperties.is_proposed,
          is_in_progress = _getProperties.is_in_progress;

      var types = [];

      if (is_open) types.push('Open');
      if (is_proposed) types.push('Proposed');
      if (is_in_progress) types.push('In Progress');

      return types;
    }),

    investmentTypes: _investment.INVESTMENT_TYPES.join('|'),
    investmentTypesArray: Ember.computed('investmentTypes', (0, _arrayify.default)('investmentTypes', '|')),
    investmentTypeOptions: _investment.INVESTMENT_TYPES,
    investmentStatuses: 'Completed',
    investmentStatusesArray: Ember.computed('investmentStatuses', (0, _arrayify.default)('investmentStatuses', '|')),
    investmentStatusesOptions: _investment.INVESTMENT_STATUSES,

    investmentSources: _investment.INVESTMENT_SOURCES.join('|'),
    investmentSourcesArray: Ember.computed('investmentSources', (0, _arrayify.default)('investmentSources', '|')),
    investmentSourcesOptions: _investment.INVESTMENT_SOURCES,

    initBlueArrow: Ember.observer('isPlottingPoint', 'mapInstance.target', function () {
      var map = this.get('mapInstance');

      if (map && this.get('isPlottingPoint')) {
        var _map$getCenter = map.getCenter(),
            lat = _map$getCenter.lat,
            lng = _map$getCenter.lng;

        this.setProperties({
          newPointLatitude: lat,
          newPointLongitude: lng
        });
      }
    }),

    refresh: function refresh() {
      this.set('_refresher', Math.random());
    },


    newPointLatitude: SOUTHWICK_LATITUDE,
    newPointLongitude: SOUTHWICK_LONGITUDE,
    visibleFeatures: Ember.computed.apply(undefined, FEATURE_PARAMS.concat(['city.places.[]', '_refresher', (0, _applyFilterTo.default)('city.places', _place.FEATURE_FILTERS_CONFIG)])),

    visibleInvestments: Ember.computed.apply(undefined, INVESTMENT_PARAMS.concat(['city.investments.[]', '_refresher', (0, _applyFilterTo.default)('city.investments', _investment.INVESTMENT_FILTERS_CONFIG)])),

    visibleParcels: Ember.computed.apply(undefined, PARCEL_PARAMS.concat(['city.parcels.[]', '_refresher', (0, _applyFilterTo.default)('city.parcels', _parcel.PARCEL_FILTERS_CONFIG)]))
  });
});