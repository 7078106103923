define("blockxblock/utils/remove-item", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = removeItem;
  function removeItem(item, list) {
    var _list = [].concat(list);
    var index = list.indexOf(item);

    if (index !== -1) {
      _list.splice(index, 1);
    }

    return _list;
  }
});