define('blockxblock/components/map-popup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var POPUP_OFFSET = 10;

  exports.default = Ember.Component.extend({
    classNames: ['ui', 'segment', 'map-popup'],
    classNameBindings: ['hidden'],
    attributeBindings: ['style'],
    style: Ember.computed('left', 'right', function () {
      var left = this.get('left') + POPUP_OFFSET;
      var top = this.get('top') + POPUP_OFFSET;
      return Ember.String.htmlSafe('left: ' + left + 'px; top: ' + top + 'px');
    }),
    hidden: true,
    currentCity: Ember.inject.service(),
    actions: {
      onMouseover: function onMouseover(event) {
        this.set('hidden', false);
        var mapInstance = this.get('currentCity.mapInstance');
        var clientClick = mapInstance.latLngToContainerPoint(event.latlng);
        this.set('top', clientClick.y);
        this.set('left', clientClick.x);
        window.point = event;
      },
      onMouseout: function onMouseout(event) {
        this.set('hidden', true);
      }
    }
  });
});