define('blockxblock/controllers/cities/city/places/edit-place', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Controller.extend({

    currentCity: inject.service(),

    internalModel: computed.alias('model'),

    addingInvestment: Ember.computed('model.investments.[]', function () {
      return this.get('model.investments.length') > 0;
    }),

    actions: {
      cancel: function cancel() {
        this.set('currentCity.isPlottingPoint', false);
      },
      toggleChangingLocation: function toggleChangingLocation(e) {
        e.preventDefault();

        var isChangingLocation = !this.get('currentCity.isPlottingPoint');

        this.set('currentCity.isPlottingPoint', isChangingLocation);

        var changeSet = [];
        changeSet[!isChangingLocation & 1] = ['currentCity.newPointLatitude', 'currentCity.newPointLongitude'];
        changeSet[isChangingLocation & 1] = ['internalModel.latitude', 'internalModel.longitude'];

        this.set(changeSet[0][0], this.get(changeSet[1][0]));
        this.set(changeSet[0][1], this.get(changeSet[1][1]));

        if (!isChangingLocation) {
          this.set('model.address', this.get('currentCity.locatedAddress'));
        }
      },
      deletePlace: function deletePlace() {
        if (this.get('isDeleting')) {
          this.get('model').destroy();
        }
      }
    }

  });
});