define('blockxblock/transforms/timeline', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized, options) {
      var data = serialized.data || (Array.isArray(serialized) ? serialized : []);

      data.forEach(function (snapshot) {
        if (snapshot.value === 'true' || snapshot.value === 'false') {
          snapshot.value = snapshot.value === 'true';
        }

        snapshot.date = new Date(snapshot.date);
      });

      return data.sort(function (a, b) {
        return b.date.getTime() - a.date.getTime();
      }).map(function (record) {
        return Ember.Object.create(record);
      });
    },
    serialize: function serialize(deserialized) {
      if (deserialized instanceof Array) {
        deserialized = deserialized.map(function (snapshot) {
          var date = snapshot.date;

          var raw = JSON.parse(JSON.stringify(snapshot));

          raw.date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

          return raw;
        });
      }

      return { data: deserialized };
    }
  });
});