define('blockxblock/models/city', ['exports', 'ember-data', 'blockxblock/utils/get-timely-snapshot', 'blockxblock/models/abstract/satisfiable'], function (exports, _emberData, _getTimelySnapshot, _satisfiable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var alias = Ember.computed.alias;


  var timelyAttributes = ['fellow_status'];
  var timelyObservers = timelyAttributes.map(function (a) {
    return a + '.[]';
  });
  var timelyMembers = 'timely.{' + timelyAttributes.join(',') + '}';

  var requiredFields = [];
  var requiredConditions = {};
  var relationships = ['places', 'investments'];

  exports.default = _emberData.default.Model.extend(_extends({}, (0, _satisfiable.default)(requiredFields, requiredConditions, relationships, timelyAttributes), {

    currentCity: Ember.inject.service(),

    // regular attributes
    city_name: _emberData.default.attr('string'),
    district_name: _emberData.default.attr('string'),
    program_start_date: _emberData.default.attr('date'),
    splash_image: _emberData.default.attr('string'),
    i2c_text: _emberData.default.attr('string'),
    district_contact_name: _emberData.default.attr('string'),
    district_contact_role: _emberData.default.attr('string'),
    district_contact_org: _emberData.default.attr('string'),
    district_contact_phone: _emberData.default.attr('string'),
    district_contact_email: _emberData.default.attr('string'),
    district_contact_website: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('number'),
    longitude: _emberData.default.attr('number'),
    imageoverlay: _emberData.default.attr('string'),
    imageoverlaybbox: _emberData.default.attr('jsonstring'),
    live: _emberData.default.attr('boolean'),

    //program_start_date: DS.attr('timeline', { defaultValue: [] }),
    fellow_status: _emberData.default.attr('timeline', { defaultValue: [] }),

    timely: Ember.computed.apply(Ember, timelyObservers.concat(['currentCity.timelineDate', function () {
      var _this = this;

      var date = this.get('currentCity.timelineDate');

      return timelyAttributes.reduce(function (attrs, attr) {
        attrs[attr] = (0, _getTimelySnapshot.default)(date, _this.get(attr) || []);
        return attrs;
      }, {});
    }])),

    historical: Ember.computed.apply(Ember, timelyObservers.concat([timelyMembers, function () {
      var _this2 = this;

      var timelines = Ember.Object.create(this.get('timely'));

      Object.keys(timelines).forEach(function (field) {
        var timeline = _this2.get(field);

        if (timeline) {
          timelines[field] = timeline.filter(function (snapshot) {
            return snapshot.date < timelines[field].date;
          });
        }
      });

      return timelines;
    }])),

    // aliases for name changes
    name: alias('city_name'),
    imageOverlay: alias('imageoverlay'),
    imageOverlayBBox: alias('imageoverlaybbox'),
    splash: alias('splash_image'),

    // relationships
    places: _emberData.default.hasMany('place'),
    parcels: _emberData.default.hasMany('parcel'),
    investments: _emberData.default.hasMany('investment')
  }));
});