define('blockxblock/utils/csv-factory', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = csvFactory;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function csvFactory(models, exclusions) {
    var keys = Object.keys(models[0].toJSON()).removeObjects(exclusions || []);

    var values = models.map(function (model) {
      return Object.values(model.getProperties.apply(model, keys)).map(function (value) {
        var serialized = value || '';

        if (value instanceof Ember.Object) {
          if (value.get('length') > 0) {
            serialized = value.map(function (v) {
              return v.get('id');
            }).reduce(function (list, v) {
              return list + ';' + v;
            });
          } else {
            serialized = value.get('id');
          }
        } else if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object') {
          if (Array.isArray(value)) {
            if (value.length > 0) {
              if (_typeof(value[0]) === 'object') {
                serialized = JSON.stringify(value);
              } else {
                serialized = value.join(';');
              }
            } else {
              serialized = null;
            }
          } else {
            serialized = JSON.stringify(value);
          }
        }

        return serialized;
      });
    });

    values.unshift(keys);

    return values;
  }
});