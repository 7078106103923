define('blockxblock/utils/months-between', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = monthsBetween;
  function monthsBetween(dateStart, dateEnd, format) {
    var timeValues = [];
    if (!format) format = 'YYYY-MM';
    dateStart = moment(dateStart);
    dateEnd = moment(dateEnd);

    while (dateEnd > dateStart) {
      timeValues.push({ date: dateStart.toDate(), alias: dateStart.format(format) });
      dateStart.add(1, 'month');
    }
    return timeValues;
  }
});