define('blockxblock/components/related-investments-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ICON_SIZE = 35;

  exports.default = Ember.Component.extend({

    classNames: ['component', 'related-investments-icon'],

    currentCity: Ember.inject.service(),

    showInvestments: true,
    showPlaces: true,

    icons: Ember.computed('feature.investments.[]', 'currentCity.visibleInvestments.[]', function () {
      var feature = this.get('feature');
      var visibleInvestments = this.get('currentCity.visibleInvestments');
      var cityId = window.location.pathname.split('/')[2];

      var icons = feature.get('investments').map(function (investment) {
        if (visibleInvestments.includes(investment)) {
          var icon = investment.get('iconUrl');
          var iconName = icon.split('/');
          iconName = iconName[iconName.length - 1].replace('.png', '');

          return '\n          <img src=' + icon + ' data-linkto="cities.city.investments" data-id="' + investment.get('id') + '" />\n        ';
        } else {
          return null;
        }
      }).filter(function (icon) {
        return icon !== null;
      });

      return icons;
    }),

    iconAnchor: [ICON_SIZE / 2, ICON_SIZE / 2],

    iconContainerSize: Ember.computed('showPlaces', 'icons.[]', 'showInvestments', function () {
      var showInvestments = this.get('showInvestments');
      var showPlaces = this.get('showPlaces');
      var pixelsForInvestments = showInvestments ? this.get('icons.length') * ICON_SIZE : 0;

      return [(showPlaces ? ICON_SIZE : 0) + pixelsForInvestments, ICON_SIZE];
    }),

    markup: Ember.computed('showPlaces', 'showInvestments', 'icons.[]', function () {
      var showInvestments = this.get('showInvestments');
      var showPlaces = this.get('showPlaces');
      var feature = this.get('feature');
      var icons = this.get('icons');

      var img = '<img data-linkto="cities.city.places" data-id="' + feature.get('id') + '" src="' + feature.get('iconUrl').dasherize() + '"/>';

      return '\n      <div class="ui mini images">\n        ' + (showPlaces ? img : '') + '\n        ' + (showInvestments ? icons : '') + '\n      </div>\n    ';
    })

  });
});