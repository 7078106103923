define('blockxblock/components/contact-fields', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({

    fields: ['name', 'org', 'title', 'phone', 'email', 'website'],

    prefixedFields: computed('fields', 'prefix', function () {
      var prefix = this.get('prefix');
      return this.get('fields').map(function (field) {
        return prefix + '_' + field;
      });
    }),

    hasType: computed('prefixedFields.[]', function () {
      return this.get('prefixedFields').some(notEmpty.bind(this));
    })

  });


  function notEmpty(field) {
    var value = this.get('model.' + field);

    return value != null && value != undefined && value != '';
  }
});