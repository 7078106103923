define('blockxblock/utils/is-within-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isWithinFilter;
  function isWithinFilter(minMaxArray, field, model) {
    var min = minMaxArray[0],
        max = minMaxArray[1];


    if (!(typeof min === 'number' && typeof max === 'number')) return true;

    var value = model.get(field);
    return value >= min && value <= max;
  }
});