define('blockxblock/helpers/attribute-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.attributeType = attributeType;
  function attributeType(params /*, hash*/) {
    var attribute = params[0];

    return Ember.typeOf(attribute);
  }

  exports.default = Ember.Helper.helper(attributeType);
});