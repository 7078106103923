define('blockxblock/helpers/is-not-empty', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isNotEmpty = isNotEmpty;
  function isNotEmpty(params /*, hash*/) {
    var type = Ember.typeOf(params[0]);
    return type !== 'undefined' && type !== 'null' && params[0] !== "";
  }

  exports.default = Ember.Helper.helper(isNotEmpty);
});