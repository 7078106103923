define('blockxblock/mixins/center-map-on-geometry', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    currentCity: Ember.inject.service(),
    positionMap: function positionMap() {
      var currentCity = this.get('currentCity');
      var model = this.modelFor(this.routeName);
      var latitude = model.get('latitude');
      var longitude = model.get('longitude');

      model.set('isSelected', true);
      Ember.run.next(this, function () {
        currentCity.setProperties({
          latitude: latitude,
          longitude: longitude
        });
      });
    },

    actions: {
      didTransition: function didTransition() {
        this.positionMap();
        return this._super();
      },
      willTransition: function willTransition() {
        var model = this.modelFor(this.routeName);
        model.set('isSelected', false);
        return this._super();
      }
    }
  });
});