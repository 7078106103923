define('blockxblock/helpers/split-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.splitString = splitString;
  function splitString(params, delimiter) {
    if (!(typeof params === 'string')) return params;
    return params.split(delimiter);
  }

  exports.default = Ember.Helper.helper(splitString);
});