define('blockxblock/utils/functions', ['exports', 'ember-string-helpers/utils/functions'], function (exports, _functions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _functions.default;
    }
  });
});