define('blockxblock/components/edit-model', ['exports', 'blockxblock/utils/validate'], function (exports, _validate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A,
      Component = Ember.Component,
      service = Ember.inject.service,
      computed = Ember.computed;
  exports.default = Component.extend({

    ajax: service(),
    currentCity: service(),
    router: service(),
    store: service(),

    point: computed('currentCity.newPointLatitude', 'currentCity.newPointLongitude', function () {
      return [this.get('currentCity.newPointLatitude'), this.get('currentCity.newPointLongitude')];
    }),

    modelName: Ember.computed('model.constructor.modelName', 'model.isDistrict', function () {
      var model = this.get('model');

      return model.get('isDistrict') ? 'district' : model.constructor.modelName;
    }),

    reverseGeocode: computed('point', 'model.id', function () {
      var _this = this;

      var point = this.get('point');
      var geocodeDebounce = this.get('geocodeDebounce');

      if (geocodeDebounce) {
        clearTimeout(geocodeDebounce);
      }

      this.set('geocodeDebounce', setTimeout(function () {
        _this.set('geocoding', true);
        _this.get('ajax').request('https://pelias.mapc.org/v1/reverse?point.lat=' + point[0] + '&point.lon=' + point[1] + '&layers=address').then(function (res) {
          var address = res.features[0].properties.name;

          _this.set('geocoding', false);
          _this.set('currentCity.locatedAddress', address);

          if (!_this.get('model.id')) {
            _this.get('model').setProperties({
              address: address,
              latitude: point[0],
              longitude: point[1]
            });
          }
        });
      }, 300));
    }),

    formSubmittable: Ember.computed('modelName', 'model.saveable', 'model.city.saveable', function () {
      if (this.get('modelName') === 'district') {
        return this.get('model.saveable') || this.get('model.city.saveable');
      }

      return this.get('model.saveable');
    }),

    submitInvestment: function submitInvestment() {
      var _this2 = this;

      var model = this.get('model');

      if (model.get('saveable') && !this.get('isSaving')) {
        this.set('isSaving', true);

        model.save().then(function () {
          _this2.get('router').transitionTo('cities.city.investments');
          _this2.get('currentCity').refresh();
        }).finally(function () {
          _this2.set('isSaving', false);
        });
      } else {
        (0, _validate.default)(model);
      }
    },
    submitParcel: function submitParcel() {
      var _this3 = this;

      var model = this.get('model');

      if (model.get('saveable') && !this.get('isSaving')) {
        this.set('isSaving', true);

        model.save().then(function () {
          _this3.get('router').transitionTo('cities.city.parcels');
          _this3.get('currentCity').refresh();
        }).finally(function () {
          _this3.set('isSaving', false);
        });
      } else {
        (0, _validate.default)(model);
      }
    },
    submitDistrict: function submitDistrict() {
      var _this4 = this;

      var place = this.get('model');
      var district = this.get('currentCity.city');

      if ((place.get('saveable') || district.get('saveable')) && !this.get('isSaving')) {
        this.set('isSaving', true);

        Ember.RSVP.allSettled([place.save(), district.save()]).then(function () {
          _this4.get('router').transitionTo('cities.city.place');
        }).finally(function () {
          return _this4.get('isSaving', false);
        });
      } else {
        (0, _validate.default)(place);
        (0, _validate.default)(district);
      }
    },
    submitPlace: function submitPlace() {
      var _this5 = this;

      var model = this.get('model');

      if (model.get('saveable') && !this.get('isSaving')) {
        this.set('isSaving', true);

        if (model.get('isDistrict')) {
          model.set('name', 'District-wide');
          model.set('status', [{ value: 'Open', date: new Date() }]);
        }

        model.save().then(function (record) {

          // Create/update investments
          var investments = model.get('investments');
          var promises = [];
          investments.forEach(function (investment) {
            investment.set('city', model.get('city'));
            promises.push(investment.save());
          });

          Ember.RSVP.allSettled(promises).then(function (_investments) {
            _this5.get('router').transitionTo('cities.city.' + model.constructor.modelName + 's', record);
            _this5.get('currentCity').refresh();
            _this5.set('currentCity.isPlottingPoint', false);
          });
        }).finally(function () {
          _this5.set('isSaving', false);
        });
      } else {
        (0, _validate.default)(model);
      }
    },


    actions: {
      cancel: function cancel(model) {
        model.rollbackAttributes();
        this.sendAction('onCancel');
        this.get('router').transitionTo('cities.city.' + model.constructor.modelName + 's', model);
      },
      addInvestment: function addInvestment(e) {
        e.preventDefault();

        this.get('model.investments').pushObject(this.get('store').createRecord('investment'));
      },
      removeInvestment: function removeInvestment(investment, e) {
        e.preventDefault();
        this.get('model.investments').removeObject(investment);
      },
      submitForm: function submitForm(e) {
        e.preventDefault();
        var model = this.get('model');

        ({
          investment: this.submitInvestment,
          place: this.submitPlace,
          parcel: this.submitParcel,
          district: this.submitDistrict
        })[this.get('modelName')].bind(this)();
      }
    }
  });
});