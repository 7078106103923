define('blockxblock/utils/is-any-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isAnyFilter;
  function isAnyFilter(typesArray, field, model) {
    var value = model.get(field);

    if (typeof typesArray === 'undefined') throw new Error("is-any-filter: nothing to compare");
    if (typesArray.length < 1) return true;
    if (value) return typesArray.includes(value) || value == 'Planning/Strategy';
    return;
  }
});