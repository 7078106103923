define('blockxblock/components/statewide-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    lat: 42,
    lng: -71.922,
    zoom: 9,
    actions: {
      linkTo: function linkTo(route, model) {
        this.transitionTo(route, model);
      }
    }
  });
});