define('blockxblock/components/main-nav', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    session: Ember.inject.service(),

    loginUsername: '',
    loginPassword: '',
    loginError: null,
    showingLogin: false,
    isLoggingIn: false,
    isLoggingOut: false,

    submittable: Ember.computed('loginUsername', 'loginPassword', function () {
      return this.get('loginUsername') !== '' && this.get('loginPassword') !== '';
    }),

    actions: {
      toggleLogin: function toggleLogin() {
        this.toggleProperty('showingLogin');

        this.set('loginUsername', '');
        this.set('loginPassword', '');
      },
      logout: function logout() {
        var _this = this;

        this.set('isLoggingOut', true);
        this.get('session').invalidate().finally(function () {
          return _this.set('isLoggingOut', false);
        });
      },
      login: function login() {
        var _this2 = this;

        if (!this.get('submittable')) {
          return;
        }

        var username = this.get('loginUsername');
        var password = this.get('loginPassword');

        this.set('loginError', null);
        this.set('isLoggingIn', true);

        this.get('session').authenticate('authenticator:drf-token-authenticator', username, password).then(function () {
          _this2.set('showingLogin', false);
        }).catch(function (e) {
          _this2.set('loginError', 'Unable to log in');
        }).finally(function () {
          _this2.set('isLoggingIn', false);
        });
      }
    }
  });
});