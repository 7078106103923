define('blockxblock/utils/is-longitudinal-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isLongitudinalFilter;
  function isLongitudinalFilter(selectedDate, longitudinalField, model) {
    if (!selectedDate) return true;

    // selectedDate, longitudinalField, model
    var truthState = 'open';
    var fieldDates = model.get(longitudinalField);
    var sortedDates = fieldDates.sortBy(function (o) {
      return new Date(o.quarter);
    });
    var state = false;

    // what is the status of the selectedDate?
    sortedDates.forEach(function (el, index) {
      var subsequentDate = sortedDates[index + 1];

      // is there a subsequent date or is this the last?
      if (subsequentDate) {
        var parsedSubsequentDate = new Date(selectedDate);

        // is the selected date greater than the current element date and less than the subsequent element date? 
        if (selectedDate > el.quarter && selectedDate < subsequentDate.quarter) {
          if (el.status == truthState) {
            state = true;
          }
        }
      } else {
        // it's the last in the array anyway, check its truth state
        if (el.status == truthState) {
          state = true;
        }
      }
    });

    return state;
  }
});