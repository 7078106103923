define('blockxblock/components/timeline-edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

  exports.default = Ember.Component.extend({
    months: MONTHS,
    year: 2016
  });
});