define('blockxblock/components/parcel-accord', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  exports.default = Ember.Component.extend({
    classNames: ['component', 'accord', 'parcel-accord'],

    session: Ember.inject.service(),

    streetAddress: alias('model.street_address'),
    landUse: alias('model.land_use'),
    useType: alias('model.use_type'),
    yearBuilt: alias('model.year_built'),
    gFloorVacancy: alias('model.ground_floor_vacancy'),
    uFloorVacancy: alias('model.upper_floor_vacancy'),
    assessedValue: alias('model.assessed_value_d'),
    historical: alias('model.historical'),
    notes: alias('model.parcel_notes'),
    internalNotes: alias('model.internal_parcel_notes'),

    listingType: alias('model.listing_type'),
    isEngagedOwner: alias('model.is_engaged_owner'),
    siteControl: alias('model.owner_type'),

    forSale: alias('model.property_for_sale_latest'),
    forLease: alias('model.property_for_lease_latest'),

    realEstateType: Ember.computed('forSale', 'forLease', function () {
      if (this.get('forSale')) return 'sale';
      if (this.get('forLease')) return 'lease';
    }),

    owner: Ember.computed('model.{owner_contact_name,owner_contact_role,owner_contact_org,owner_contact_phone,owner_contact_email,owner_contact_website}', function () {
      return {
        name: this.get('model.owner_contact_name'),
        role: this.get('model.owner_contact_role'),
        org: this.get('model.owner_contact_org'),
        phone: this.get('model.owner_contact_phone'),
        email: this.get('model.owner_contact_email'),
        website: this.get('model.owner_contact_website')
      };
    }),

    realEstateAgent: Ember.computed('model.{realestate_contact_name,realestate_contact_role,realestate_contact_organization,realestate_contact_phone,realestate_contact_email,realestate_contact_website}', function () {
      var model = this.get('model');

      return {
        name: model.get('realestate_contact_name'),
        role: model.get('realestate_contact_role'),
        org: model.get('realestate_contact_organization'),
        phone: model.get('realestate_contact_phone'),
        email: model.get('realestate_contact_email'),
        website: model.get('realestate_contact_website')
      };
    }),

    didInsertElement: function didInsertElement() {
      Ember.run.schedule('afterRender', this, function () {
        this.$('.ui.accordion').accordion();
      });
    }
  });
});