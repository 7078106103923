define('blockxblock/components/district-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      onSubmit: function onSubmit() {
        this.sendAction('onSubmit', this.get('model'));
      }
    }

  });
});