define('blockxblock/components/date-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['component', 'date-field'],

    year: Ember.computed('value', function () {
      return String(this.get('value').getFullYear());
    }),

    month: Ember.computed('value', function () {
      return ('0' + String(this.get('value').getMonth() + 1)).slice(-2);
    }),

    actions: {
      setYear: function setYear(year) {
        this.setDate(this.get('month') - 1, year);
      },
      setMonth: function setMonth(month) {
        this.setDate(parseInt(month) - 1, this.get('year'));
      }
    },

    setDate: function setDate(month, year) {
      var date = new Date();
      date.setMonth(month);
      date.setYear(year);

      this.set('value', date);
    }
  });
});