define('blockxblock/utils/not-empty', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = notEmpty;
  function notEmpty(value) {
    if (Array.isArray(value)) {
      return value.length > 0 && value.every(function (v) {
        return _notEmpty(v.value);
      });
    }

    return _notEmpty(value);
  }

  function _notEmpty(value) {
    return value != undefined && value != null && value != '';
  }
});