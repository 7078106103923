define('blockxblock/components/timeline-builder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

  exports.default = Ember.Component.extend({
    setupSnapshots: function () {
      var snapshots = this.get('snapshots');
      if (!snapshots) {
        this.set('snapshots', Em.A([]));
      }
    }.on('init'),
    months: MONTHS,
    month: '01',
    year: 2016,
    status: null,
    snapshots: [],
    orderedSnapshots: Ember.computed('snapshots.[]', function () {
      var snapshots = this.get('snapshots');
      if (!Ember.isEmpty(snapshots)) {
        return snapshots.sortBy(function (el) {
          return el.date;
        });
      } else {
        return snapshots;
      }
    }),
    actions: {
      addNew: function addNew() {
        var _getProperties = this.getProperties('snapshots', 'month', 'year', 'choices.firstObject'),
            snapshots = _getProperties.snapshots,
            month = _getProperties.month,
            year = _getProperties.year,
            choice = _getProperties.choice;

        if (snapshots) {
          snapshots.pushObject({ date: year + '-' + month, status: choice });
        } else {
          snapshots.pushObject({ date: year + '-' + month, status: choice });
        }
      }
    }
  });
});