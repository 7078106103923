define("blockxblock/utils/is-true-filter", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isTrueFilter;
  function isTrueFilter(filter, field, model) {
    if (!filter) return true;
    return model.get(field) === filter;
  }
});