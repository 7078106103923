define('blockxblock/helpers/upper-case', ['exports', 'ember-string-helpers/helpers/upper-case'], function (exports, _upperCase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _upperCase.default;
    }
  });
  Object.defineProperty(exports, 'upperCase', {
    enumerable: true,
    get: function () {
      return _upperCase.upperCase;
    }
  });
});