define('blockxblock/utils/is-timely-filter', ['exports', 'blockxblock/utils/get-timely-snapshot'], function (exports, _getTimelySnapshot) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isTimelyFilter;
  function isTimelyFilter(filter, field, model) {
    return (0, _getTimelySnapshot.default)(filter, model.get(field)) !== null;
  } /*
     * This filter iterates through the model's timeline snapshots
     * to find if there is a snapshot with a date before the value
     * that we are filtering by.
     *
     * THIS FILTER ASSUMES THAT SNAPSHOTS HAVE BEEN SORTED BY DATE
     * IN DESCENDING ORDER!
     */
});