define('blockxblock/controllers/cities/city/new-place', ['exports', 'blockxblock/models/place'], function (exports, _place) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer;
  exports.default = Controller.extend({

    currentCity: inject.service(),

    locationType: _place.LOCATION_TYPES.ADDRESS,
    locationTypes: [_place.LOCATION_TYPES.ADDRESS, _place.LOCATION_TYPES.DESCRIBE, _place.LOCATION_TYPES.DISTRICT],

    isDistrictWide: computed.equal('locationType', _place.LOCATION_TYPES.DISTRICT),
    isAddress: computed.equal('locationType', _place.LOCATION_TYPES.ADDRESS),
    isDescribable: computed.equal('locationType', _place.LOCATION_TYPES.DESCRIBE),

    internalModel: computed('privateDocuments.[]', 'locationType', 'currentCity.locatedAddress', function () {
      var places = this.get('model.city.places');
      var model = this.get('model.newRecord');

      if (this.get('isDistrictWide')) {
        var districtPlace = (places.filterBy('location_type', _place.LOCATION_TYPES.DISTRICT) || [null])[0];

        if (districtPlace) {
          model = districtPlace;
        }
      }

      if (this.get('isAddress')) {
        var withSameAddress = places.filterBy('address', this.get('currentCity.locatedAddress'));
        if (withSameAddress.length > 0) {
          model = withSameAddress[0];
        }
      }

      model.set('location_type', this.get('locationType'));

      return model;
    })

  });
});