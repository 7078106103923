define('blockxblock/serializers/city', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.JSONSerializer.extend({
        serialize: function serialize(snapshot, options) {
            var serialized = this._super(snapshot, options);
            console.log(serialized);
            return serialized;
        }
    });
});