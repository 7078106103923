define('blockxblock/components/select-association', ['exports', 'ember-inflector', 'blockxblock/components/abstract/required-field'], function (exports, _emberInflector, _requiredField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _requiredField.default.extend({

    currentCity: Ember.inject.service(),

    classNames: ['component', 'select-assocation'],

    modelName: null,
    selected: [],

    options: Ember.computed('modelName', function () {
      var inflector = new _emberInflector.default(_emberInflector.default.defaultRules);
      var modelName = this.get('modelName');
      var inflectedModelName = inflector.pluralize(modelName);
      return this.get('currentCity.city.' + inflectedModelName);
    })
  });
});