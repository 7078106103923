define('blockxblock/components/snapshot-field', ['exports', 'blockxblock/components/abstract/required-field', 'blockxblock/utils/validate'], function (exports, _requiredField, _validate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _requiredField.default.extend({
    classNames: ['component', 'snapshot-field'],

    init: function init() {
      this._super();

      Ember.defineProperty(this, 'values', Ember.computed.alias('model.' + this.get('field')));
    },


    valuesChanged: Ember.observer('values.@each.value', function () {
      if (this.get('snapshotAdded')) {
        this.set('snapshotAdded', false);
      } else {
        (0, _validate.default)(this.get('model'), this.get('field'));
      }
    }),

    actions: {
      add: function add(e) {
        e.preventDefault();
        this.addSnapshot();
      },
      remove: function remove(snapshot, e) {
        e.preventDefault();
        this.get('values').removeObject(snapshot);
      }
    },

    addSnapshot: function addSnapshot() {
      if (this.get('values') === undefined) {
        this.set('values', Ember.A());
      }

      this.set('snapshotAdded', true);

      this.get('values').pushObject({
        value: null,
        date: new Date()
      });
    }
  });
});