define('blockxblock/helpers/get-types-for', ['exports', 'fetch'], function (exports, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getTypesFor = getTypesFor;
  var get = Ember.get;
  function getTypesFor(_ref) {
    var table = _ref[0],
        field = _ref[1];

    return (0, _fetch.default)('/data/' + table + '.json').then(function (blob) {
      return blob.json();
    }).then(function (placesFields) {
      return get(placesFields, field + '.enum');
    });
  }

  exports.default = Ember.Helper.helper(getTypesFor);
});