define('blockxblock/routes/cities/city/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel(_ref) {
      var params = _ref.params;

      this.transitionTo('cities.city.show', params['cities.city'].city_id);
    }
  });
});