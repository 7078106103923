define('blockxblock/routes/cities/city/new-place', ['exports', 'blockxblock/models/place', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _place, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentCity: Ember.inject.service(),

    model: function model(params) {
      var city = this.modelFor('cities.city');

      return {
        city: city,
        newRecord: this.store.createRecord('place', {
          city: city.city,
          location_type: 'Street address'
        })
      };
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('featureTypes', _place.FEATURE_TYPES);
    },


    actions: {
      didTransition: function didTransition() {
        var currentCity = this.get('currentCity');
        currentCity.set('isPlottingPoint', true);
      },
      willTransition: function willTransition(transition) {
        var currentCity = this.get('currentCity');
        currentCity.set('isPlottingPoint', false);
      },
      submitRoute: function submitRoute(object) {
        var _this = this;

        var currentCity = this.get('currentCity');

        object.setProperties({
          latitude: currentCity.get('newPointLatitude'),
          longitude: currentCity.get('newPointLongitude')
        });

        return object.save().then(function (model) {
          _this.transitionTo('cities.city.places', model);
        });
      }
    }
  });
});