define('blockxblock/models/place', ['exports', 'ember-data', 'blockxblock/config/environment', 'blockxblock/utils/get-timely-snapshot', 'blockxblock/models/abstract/satisfiable'], function (exports, _emberData, _environment, _getTimelySnapshot, _satisfiable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.FEATURE_FILTERS_CONFIG = exports.FEATURE_SUBTYPES = exports.FEATURE_TYPES = exports.LOCATION_TYPES = exports.FEATURE_EMPLOYMENTS = exports.FEATURE_STATUSES = undefined;

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var alias = Ember.computed.alias;


  var timelyAttributes = ['status', 'employment', 'activating', 'community_hub', 'tdi_asset', 'engaged_owner'];
  var timelyObservers = timelyAttributes.map(function (a) {
    return a + '.[]';
  });
  var timelyMembers = 'timely.{' + timelyAttributes.join(',') + '}';

  var requiredFields = ['name', 'latitude', 'longitude', 'description', 'place_type', 'subtype', 'status', 'related_places_text', 'related_investments_text'];

  var requiredConditions = {
    isDescribable: 'description',
    hasRelatedPlaces: 'related_places_text',
    hasInvestments: 'related_investments_text',
    isNotDistrict: requiredFields
  };

  var relationships = ['related_places.[]', 'investments.[]', 'city'];

  exports.default = _emberData.default.Model.extend(_extends({}, (0, _satisfiable.default)(requiredFields, requiredConditions, relationships, timelyAttributes), {

    currentCity: Ember.inject.service(),
    place_id: _emberData.default.attr('string'),

    name: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('number'),
    longitude: _emberData.default.attr('number'),
    location_type: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    is_in_district: _emberData.default.attr('boolean'),
    place_type: _emberData.default.attr('string'),
    type: Ember.computed.alias('place_type'),
    subtype: _emberData.default.attr('string'),
    tdi_asset_start: _emberData.default.attr('date'),
    engaged_owner_start: _emberData.default.attr('date'),
    owner_name: _emberData.default.attr('string'),
    owner_title: _emberData.default.attr('string'),
    owner_org: _emberData.default.attr('string'),
    owner_phone: _emberData.default.attr('phone'),
    owner_email: _emberData.default.attr('email'),
    owner_website: _emberData.default.attr('url'),
    notes: _emberData.default.attr('string'),
    file_upload: _emberData.default.attr('string'),
    link_description: _emberData.default.attr('string'),
    link_url: _emberData.default.attr('url'),
    internal_notes: _emberData.default.attr('string'),
    internal_files: _emberData.default.attr('string'),
    splash: _emberData.default.attr('string'),
    i2c_text: _emberData.default.attr('string'),
    pub_name: _emberData.default.attr('string'),
    pub_role: _emberData.default.attr('string'),
    pub_title: Ember.computed.alias('pub_role'),
    pub_organization: _emberData.default.attr('string'),
    pub_org: Ember.computed.alias('pub_organization'),
    pub_phone: _emberData.default.attr('phone'),
    pub_email: _emberData.default.attr('email'),
    pub_website: _emberData.default.attr('url'),
    related_places_text: _emberData.default.attr('string'),
    related_investments_text: _emberData.default.attr('string'),
    status: _emberData.default.attr('timeline', { defaultValue: [] }),
    employment: _emberData.default.attr('timeline', { defaultValue: [] }),
    activating: _emberData.default.attr('timeline', { defaultValue: [] }),
    community_hub: _emberData.default.attr('timeline', { defaultValue: [] }),
    tdi_asset: _emberData.default.attr('timeline', { defaultValue: [] }),
    engaged_owner: _emberData.default.attr('timeline', { defaultValue: [] }),

    timely: Ember.computed.apply(Ember, timelyObservers.concat(['currentCity.timelineDate', function () {
      var _this = this;

      var date = this.get('currentCity.timelineDate');

      return timelyAttributes.reduce(function (attrs, attr) {
        attrs[attr] = (0, _getTimelySnapshot.default)(date, _this.get(attr) || []);
        return attrs;
      }, {});
    }])),

    isEngaged: Ember.computed('timely.engaged_owner.value', function () {
      return this.get('timely.engaged_owner.value');
    }),

    isEmployer: Ember.computed('timely.employment.value', function () {
      return this.get('timely.employment.value');
    }),

    isActivating: Ember.computed('timely.activating.value', function () {
      return this.get('timely.activating.value');
    }),

    isCommunityHub: Ember.computed('timely.community_hub.value', function () {
      return this.get('timely.community_hub.value');
    }),

    isTDIAsset: Ember.computed('timely.tdi_asset.value', function () {
      return this.get('timely.tdi_asset.value');
    }),

    isDistrict: Ember.computed('location_type', function () {
      return this.get('location_type').toLowerCase().split(' ').join('-') === 'district-wide';
    }),

    isNotDistrict: Ember.computed.not('isDistrict'),

    isDescribable: Ember.computed('location_type', function () {
      return this.get('location_type').toLowerCase() === 'describable';
    }),

    isAddress: Ember.computed('location_type', function () {
      return this.get('location_type').toLowerCase().split(' ').join('-') === 'street-address';
    }),

    hasRelatedPlaces: Ember.computed('related_places.[]', function () {
      return this.get('related_places.length') > 0;
    }),

    hasInvestments: Ember.computed('investments.[]', function () {
      return this.get('investments.length') > 0;
    }),

    hasDirtyRelationships: Ember.computed('investments.@each.saveable', function () {
      var investmentsSaveable = this.get('investments').toArray().some(function (investment) {
        return investment.get('saveable');
      });

      return investmentsSaveable;
    }),

    historical: Ember.computed.apply(Ember, timelyObservers.concat([timelyMembers, function () {
      var _this2 = this;

      var timelines = Ember.Object.create(this.get('timely'));

      Object.keys(timelines).forEach(function (field) {
        var timeline = _this2.get(field);

        if (timeline) {
          timelines[field] = timeline.filter(function (snapshot) {
            return snapshot.date < timelines[field].date;
          });
        }
      });

      return timelines;
    }])),

    // aliases
    feature_name: alias('name'),
    assetType: alias('type'),
    feature_type: Ember.computed('type', 'location_type', function () {
      return this.get('type') || this.get('location_type');
    }),

    // computeds
    is_employer: Ember.computed('timely.employment.value', function () {
      var employment = this.get('timely.employment.value');
      return !!employment && employment != 0;
    }),
    is_activating: Ember.computed('timely.activating.value', function () {
      return this.get('timely.activating.value') == true;
    }),
    is_tdiasset: Ember.computed('timely.tdi_asset.value', function () {
      return this.get('timely.tdi_asset.value') == true;
    }),
    is_engaged: Ember.computed('timely.engaged_owner.value', function () {
      return this.get('timely.engaged_owner.value') == true;
    }),
    is_community_hub: Ember.computed('timely.community_hub.value', function () {
      return this.get('timely.community_hub.value') == true;
    }),
    engaged_from: Ember.computed('timely.engaged_owner', function () {
      if (this.get('timely.engaged_owner.value') == true) {
        return this.get('timely.engaged_owner.date');
      } else {
        return '';
      }
    }),

    open_on: Ember.computed('timely.status', function () {
      if (this.get('timely.status.value') == 'Open') {
        return this.get('timely.status.date');
      }
    }),
    close_on: Ember.computed('timely.status', function () {
      if (this.get('timely.status.value') == 'Closed') {
        return this.get('timely.status.date');
      }
    }),

    investmentAmount: Ember.computed('investments.[]', function () {
      var investments_rel = this.get('investments').mapBy('investmentVal');
      var amount_t = 0;
      investments_rel.forEach(function (ea) {
        if (ea) {
          amount_t = amount_t + ea;
        }
      });
      return amount_t;
    }),

    more_info_link_url: Ember.computed('link_url', function () {
      return this.get('link_url');
    }),
    more_info_link_desc: Ember.computed('link_description', function () {
      return this.get('link_description');
    }),
    iconUrl: Ember.computed('feature_type', function () {
      var featureType = this.get('feature_type').dasherize().replace('/', '');
      return (_environment.default.prepend ? _environment.default.prepend : '/') + 'images/icons/features/' + featureType + '.png';
    }),
    splash_image: Ember.computed('isDistrict', 'splash', 'latitude', 'longitude', function () {
      if (this.get('isDistrict')) {
        return this.get('city.splash');
      }

      var _getProperties = this.getProperties('latitude', 'longitude'),
          latitude = _getProperties.latitude,
          longitude = _getProperties.longitude;

      var splash = this.get('splash');
      return splash || 'https://maps.googleapis.com/maps/api/streetview?size=450x300&location=' + latitude + ',' + longitude + '&key=AIzaSyCO654zBIabvjSOV4Ys59Pku8pmzM387ps';
    }),

    is_closed: Ember.computed('timely.status.value', function () {
      return this.get('timely.status.value') === 'Closed';
    }),

    // relationships
    city: _emberData.default.belongsTo("city"),
    related_places: _emberData.default.hasMany('place', { inverse: null }),
    investments: _emberData.default.hasMany('investment', { inverse: 'place_id' }),

    // local state
    isSelected: false

  }));


  // form / filter types
  var FEATURE_STATUSES = exports.FEATURE_STATUSES = ["Proposed", 'In Progress', 'Open', 'Closed'];
  var FEATURE_EMPLOYMENTS = exports.FEATURE_EMPLOYMENTS = ['0', '1-4', '5-9', '10-19', '20-99', '100-499', '500+'];
  var LOCATION_TYPES = exports.LOCATION_TYPES = { ADDRESS: 'Street address', DESCRIBE: 'Describable', DISTRICT: 'District-wide' };
  var FEATURE_TYPES = exports.FEATURE_TYPES = ["Arts and Culture", "Civic Institution", "Education", "Food Sales", "Health Care", "Office", "Open Space", "Parking", "Public Transit", "Housing", "Retail", "Temporary", "Mixed-Use Development"];
  var FEATURE_SUBTYPES = exports.FEATURE_SUBTYPES = {
    "Food Sales": ["Coffee Shop", "Bar", "Specialty Food Sales", "Sit-down Restaurant", "Counter Service Restaurant", "Fast-Food Chain", "Grocery Store", "Farmer's Market", "Restaurant Incubator", "Regular Food Truck Hub"],
    "Office": ["General Office", "Corporation Headquarters", "Coworking Space"],
    "Retail": ["Convenience / Corner Store", "Specialty Retailer", "Department Store", "Discount Retailer", "Resale/Consignment", "Specialty Services", "Big Box Store", "Pharmacy", "Bank", "Indoor Recreation", "Automotive", "General Retail"],
    "Community": ["Non-Profit", "Groups or Associations", "Church", "Coworking Space"],
    "Arts and Culture": ["Performance Theatre", "Music Venue", "Movie Theatre", "Museum/Gallery", "Art Center/Production Space", "Community Gathering Space"],
    "Health Care": ["Specialty Health Services", "Medical Office", "Walk-in Clinic", "Hospital"],
    "Education": ["Pre-School or Early Education Center", "Public School", "Private School", "Vocational School", "College or University", "Supplemental Services"],
    "Civic Institution": ["Fire Department", "Library", "Police Department", "City Government", "Social Services", "Public Agency", "Community Group", "Post Office", "Court"],
    "Temporary": ["Retail", "Food Sales", "Workspace", "Event Location", "Park/Parklet", "Streetscape Improvements", "Other"],
    "Park / Open Space": ["Plaza", "Alleyway", "Pocket Park", "Park", "Trails and Greenways", "Community Garden / Farm", "Active Recreation Facility"],
    "Parking": ["Free Lot", "Paid Lot", "Paid Structure"],
    "Public Transit": ["Local/Regional Bus Stop", "Light Rail Station", "High-Speed Rail Station", "Transportation Center"],
    "Housing": ["Single Family", "2-4 units", "2-4 units, subsidized", "4-10 units", "4-10 units, subsidized", "10+ units", "10+ units, subsidized", "Assisted Living"]
  };

  // DSL for filter menus
  var FEATURE_FILTERS_CONFIG = exports.FEATURE_FILTERS_CONFIG = [{
    property: 'feature_type',
    filter: 'assetTypesArray',
    filterType: 'isAny'
  }, {
    property: 'is_activating',
    filter: 'activating',
    filterType: 'isTrue'
  }, {
    property: 'timely.status.value',
    filter: 'statusTypesArray',
    filterType: 'isAny'
  }, {
    property: 'is_employer',
    filter: 'is_employer',
    filterType: 'isTrue'
  }, {
    property: 'tdi_asset',
    filter: 'tdi_asset',
    filterType: 'isTrue'
  }, {
    property: 'engaged_owner',
    filter: 'engaged_owner',
    filterType: 'isTrue'
  }, {
    property: 'is_community_hub',
    filter: 'community_hub',
    filterType: 'isTrue'
  }, {
    property: 'is_closed',
    filter: 'showClosedPlaces',
    filterType: 'isTrue'
  }, {
    property: 'status',
    filter: 'timelineDate',
    filterType: 'isTimely'
  }];
});