define('blockxblock/components/file-field-progress', ['exports', 'blockxblock/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        url: _environment.default.hosts.api + '/api/upload',

        progress: 0,
        message: 'Uploading',
        barStyle: Ember.computed('progress', function () {
            var progress = this.get('progress');
            return Ember.String.htmlSafe('transition-duration: 300ms; width: ' + progress + '%;');
        }),

        actions: {
            onUpload: function onUpload(files) {
                var fileURI = _environment.default.hosts.api + '/media/' + files[0].name;
                this.set('uploadedFiles', fileURI);
            }
        }
    });
});