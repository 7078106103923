define('blockxblock/models/investment', ['exports', 'ember-data', 'blockxblock/config/environment', 'blockxblock/utils/get-timely-snapshot', 'blockxblock/models/abstract/satisfiable'], function (exports, _emberData, _environment, _getTimelySnapshot, _satisfiable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.INVESTMENT_FILTERS_CONFIG = exports.TDI_INFLUENCES = exports.PRIVATE_PRODUCT_CHOICES = exports.INVESTMENT_SOURCES = exports.INVESTMENT_STATUSES = exports.INVESTMENT_TYPES = undefined;

  var _extends2;

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var alias = Ember.computed.alias;


  var mapTypes = function mapTypes(type) {
    var typeMap = {
      'planning/strategy': 'planning-or-strategy'
    };

    return typeMap[type.toLowerCase()] || type;
  };

  var timelyAttributes = ['investment_status', 'tdi_influence'];
  var timelyObservers = timelyAttributes.map(function (a) {
    return a + '.[]';
  });
  var timelyMembers = 'timely.{' + timelyAttributes.join(',') + '}';

  var requiredFields = ['investment_descriptor', 'investment_status', 'use_type', 'source_type', 'product', 'related_investments_text'];

  var requiredConditions = {
    hasRelatedInvestments: 'related_investments_text'
  };

  var relationships = ['related_places', 'related_investments'];

  exports.default = _emberData.default.Model.extend(_extends({}, (0, _satisfiable.default)(requiredFields, requiredConditions, relationships, timelyAttributes), (_extends2 = {

    currentCity: Ember.inject.service(),

    // new attributes
    place_id: _emberData.default.attr('string'),
    investment_id: _emberData.default.attr('string'),
    investment_descriptor: _emberData.default.attr('string'),
    street_address: _emberData.default.attr('string'),
    location_description: _emberData.default.attr('string'),
    in_district: _emberData.default.attr('boolean'),
    source_type: _emberData.default.attr('string'),
    product: _emberData.default.attr('string'),
    use_type: _emberData.default.attr('string'),
    amount_is_public: _emberData.default.attr('boolean'),
    amount_is_exact: _emberData.default.attr('boolean'),
    exact_amount: _emberData.default.attr('number'),
    estimated_amount: _emberData.default.attr('number'),
    contact_type: _emberData.default.attr('string'),
    inv_contact_name: _emberData.default.attr('string'),
    inv_contact_role: _emberData.default.attr('string'),
    inv_contact_org: _emberData.default.attr('string'),
    inv_contact_phone: _emberData.default.attr('phone'),
    inv_contact_email: _emberData.default.attr('email'),
    inv_contact_website: _emberData.default.attr('url'),
    total_project_amount: _emberData.default.attr('number'),
    notes: _emberData.default.attr('text'),
    file_upload: _emberData.default.attr('string'),
    link_description: _emberData.default.attr('string'),
    link_url: _emberData.default.attr('url'),
    internal_notes: _emberData.default.attr('text'),
    internal_files: _emberData.default.attr('string'),
    splash: _emberData.default.attr('string'),
    i2c_text: _emberData.default.attr('text'),
    pub_name: _emberData.default.attr('string'),
    pub_role: _emberData.default.attr('string'),
    pub_title: Ember.computed.alias('pub_role'),
    pub_organization: _emberData.default.attr('string'),
    pub_org: Ember.computed.alias('pub_organization'),
    pub_phone: _emberData.default.attr('phone'),
    pub_email: _emberData.default.attr('email'),
    pub_website: _emberData.default.attr('url'),
    related_investments_text: _emberData.default.attr('text'),

    investment_status: _emberData.default.attr('timeline', { defaultValue: [] }),
    tdi_influence: _emberData.default.attr('timeline', { defaultValue: [] }),

    timely: Ember.computed.apply(Ember, timelyObservers.concat(['currentCity.timelineDate', function () {
      var _this = this;

      var date = this.get('currentCity.timelineDate');

      return timelyAttributes.reduce(function (attrs, attr) {
        attrs[attr] = (0, _getTimelySnapshot.default)(date, _this.get(attr) || []);
        return attrs;
      }, {});
    }])),

    historical: Ember.computed.apply(Ember, timelyObservers.concat([timelyMembers, function () {
      var _this2 = this;

      var timelines = Ember.Object.create(this.get('timely'));

      Object.keys(timelines).forEach(function (field) {
        var timeline = _this2.get(field);

        if (timeline) {
          timelines[field] = timeline.filter(function (snapshot) {
            return snapshot.date < timelines[field].date;
          });
        }
      });

      return timelines;
    }])),

    hasRelatedInvestments: Ember.computed('related_investments.[]', function () {
      return this.get('related_investments.length') > 0;
    }),

    // aliases
    investment_status_val: alias('timely.investment_status.value'),
    tdi_influence_val: Ember.computed('timely.tdi_influence.value', function () {
      var value = this.get('timely.tdi_influence.value');

      return value ? value !== 'None' && value !== 'N/A' : false;
    }),

    investmentVal: Ember.computed('amount_is_exact', 'estimated_amount', 'exact_amount', function () {
      return this.get('amount_is_exact') ? this.get('exact_amount') : this.get('estimated_amount');
    }),

    // computeds
    iconUrl: Ember.computed('source_type', 'investment_type', function () {
      var _getProperties = this.getProperties('source_type', 'investment_type'),
          source_type = _getProperties.source_type,
          investment_type = _getProperties.investment_type;

      if (source_type && investment_type) {
        return (_environment.default.prepend ? _environment.default.prepend : '/') + 'images/icons/investments/' + source_type.decamelize() + '/' + mapTypes(investment_type).dasherize() + '.png';
      }
    }),

    displayName: Ember.computed('investment_descriptor', 'product.name', function () {
      return this.get('investment_descriptor') || this.get('product.name');
    }),

    useType: Ember.computed.alias('use_type'),

    // aliases
    investment_type: alias('use_type'),

    // relationships
    related_investments: _emberData.default.hasMany('investment', { inverse: null }),
    related_places: _emberData.default.hasMany('place')
  }, _extends2['place_id'] = _emberData.default.belongsTo('place'), _extends2.city = _emberData.default.belongsTo("city"), _extends2['splash'] = alias('place.splash'), _extends2.isSelected = false, _extends2)));


  // form options/categories
  var INVESTMENT_TYPES = exports.INVESTMENT_TYPES = ['Infrastructure', 'Finance', 'Planning or Strategy', 'Placemaking', 'Small Business Growth'];
  var INVESTMENT_STATUSES = exports.INVESTMENT_STATUSES = ['Proposed', 'In Progress', 'Completed', 'Failed'];
  var INVESTMENT_SOURCES = exports.INVESTMENT_SOURCES = ['MassDevelopment', 'Public', 'Private', 'TDI'];
  var PRIVATE_PRODUCT_CHOICES = exports.PRIVATE_PRODUCT_CHOICES = ['Building Purchase', 'Facade Improvement', 'Building Rehab', 'Interior Rehab', 'New Lease', 'New Business', 'Crowdfunding', 'Philanthropy', 'Other Private Funds'];
  var TDI_INFLUENCES = exports.TDI_INFLUENCES = ['TDI-influenced', 'TDI-assisted', 'Second Order Influence', 'None', 'N/A'];

  // filtering DSL
  var INVESTMENT_FILTERS_CONFIG = exports.INVESTMENT_FILTERS_CONFIG = [{
    property: 'investment_type',
    filter: 'investmentTypesArray',
    filterType: 'isAny'
  }, {
    property: 'investment_status_val',
    filter: 'investmentStatusesArray',
    filterType: 'isAny'
  }, {
    property: 'source_type',
    filter: 'investmentSourcesArray',
    filterType: 'isAny'
  }, {
    property: 'investmentVal',
    filter: ['valueMin', 'valueMax'],
    filterType: 'isWithin'
  }, {
    property: 'tdi_influence_val',
    filter: 'is_tdi_influenced',
    filterType: 'isTrue'
  }, {
    property: 'investment_status',
    filter: 'timelineDate',
    filterType: 'isTimely'
  }];
});