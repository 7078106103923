define('blockxblock/components/basemap-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    currentCity: Ember.inject.service(),
    classNames: ['component', 'basemap-menu'],

    x: 1,
    y: 1,
    z: 1,
    ready: Ember.computed('x', 'y', 'z', function () {
      var _getProperties = this.getProperties('x', 'y', 'z'),
          x = _getProperties.x,
          y = _getProperties.y,
          z = _getProperties.z;

      return x && y && z;
    }),

    actions: {
      setTimelineMonth: function setTimelineMonth(month) {
        this.set('currentCity.timelineMonth', month);
      },
      setTimelineYear: function setTimelineYear(year) {
        this.set('currentCity.timelineYear', year);
      }
    }
  });
});