define('blockxblock/components/file-upload', ['exports', 'ember-uploader/uploaders/uploader', 'ember-uploader/components/file-field'], function (exports, _uploader, _fileField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fileField.default.extend({
    progress: null,
    filesDidChange: function filesDidChange(files) {
      var _this = this;

      this.sendAction('onUpload', files);

      var uploader = _uploader.default.create({
        url: this.get('url')
      });

      if (!Ember.isEmpty(files)) {
        uploader.upload(files[0]);
      }

      uploader.on('progress', function (e) {
        _this.set('progress', e.percent);
      });

      uploader.on('didUpload', function (e) {
        _this.set('progress', 100);
        _this.set('message', 'Success!');
      });
    }
  });
});