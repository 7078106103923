define('blockxblock/components/date-selector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['component', 'date-selector'],

    options: Ember.computed('startValue', 'scope', function () {
      var _getProperties = this.getProperties('startValue', 'scope'),
          startValue = _getProperties.startValue,
          scope = _getProperties.scope;

      if (scope === 'month') {
        var months = [];
        for (var i = 0; i < 12;) {
          months.push(String(++i >= 10 ? i : '0' + i));
        }

        return months;
      } else {
        // year
        var startYear = this.get('startValue') || 2013;
        var endYear = new Date().getFullYear();
        var range = Math.abs(startYear - endYear) + 1; // Inclusive year range

        var years = [];
        for (var _i = 0; _i < range; _i++) {
          years.push(String(startYear + _i));
        }

        return years;
      }
    }),

    actions: {
      updateValue: function updateValue(_ref) {
        var target = _ref.target;

        this.sendAction('onSelect', target.value);
      }
    }

  });
});