define('blockxblock/components/place-form', ['exports', 'blockxblock/models/place'], function (exports, _place) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    placeTypes: _place.FEATURE_TYPES,
    placeSubTypes: Ember.computed('model.type', function () {
      return _place.FEATURE_SUBTYPES[this.get('model.type')];
    }),
    statuses: _place.FEATURE_STATUSES,
    employments: _place.FEATURE_EMPLOYMENTS

  });
});