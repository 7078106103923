define('blockxblock/authenticators/drf-token-authenticator', ['exports', 'ember-simple-auth/authenticators/base', 'blockxblock/config/environment'], function (exports, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    restore: function restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data.token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    authenticate: function authenticate(username, password) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          url: _environment.default.hosts.api + '/api-token-auth/',
          type: 'POST',
          data: JSON.stringify({
            username: username,
            password: password
          }),
          contentType: 'application/json;charset=utf-8',
          dataType: 'json'
        }).then(function (response) {
          Ember.run(function () {
            resolve({
              token: response.token
            });
          });
        }, function (xhr, status, error) {
          var response = xhr.responseText;
          Ember.run(function () {
            reject(response);
          });
        });
      });
    }
  });
});