define('blockxblock/models/parcel', ['exports', 'ember-data', 'npm:wicket', 'blockxblock/utils/get-timely-snapshot', 'blockxblock/models/abstract/satisfiable'], function (exports, _emberData, _npmWicket, _getTimelySnapshot, _satisfiable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TENURE_STATUSES = exports.UFVACANCY_STATUSES = exports.GFVACANCY_STATUSES = exports.PARCEL_TYPES = exports.PARCEL_OWNERSHIP_TYPES = exports.PARCEL_MAP_CONFIG = exports.PARCEL_FILTERS_CONFIG = undefined;

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var alias = Ember.computed.alias;


  var timelyAttributes = ['property_for_sale', 'property_for_lease', 'ground_floor_vacancy_status', 'upper_floor_vacancy_status', 'assessed_value', 'site_control', 'engaged_owner'];
  var timelyObservers = timelyAttributes.map(function (a) {
    return a + '.[]';
  });
  var timelyMembers = 'timely.{' + timelyAttributes.join(',') + '}';

  var geojsonAttributes = ['land_use', 'owner_type', 'ground_floor_vacancy', 'upper_floor_vacancy', 'tenure_status'];

  var requiredFields = ['listing_type', 'realestate_contact_name', 'realestate_contact_role', 'realestate_contact_org', 'realestate_contact_phone', 'realestate_contact_email', 'realestate_contact_website'];

  var requiredConditions = {
    isForSaleOrLease: requiredFields
  };

  exports.default = _emberData.default.Model.extend(_extends({}, (0, _satisfiable.default)(requiredFields, requiredConditions, [/*relationships*/], timelyAttributes), {

    currentCity: Ember.inject.service(),

    // new attributes
    parcel_id: _emberData.default.attr('string'),
    street_address: _emberData.default.attr('string'),
    listing_type: _emberData.default.attr('string'),
    invitation_to_connect: _emberData.default.attr('boolean'),
    invitation_to_connect_text: _emberData.default.attr('string'),
    realestate_contact_name: _emberData.default.attr('string'),
    realestate_contact_role: _emberData.default.attr('string'),
    realestate_contact_organization: _emberData.default.attr('string'),
    realestate_contact_org: Ember.computed.alias('realestate_contact_organization'),
    realestate_contact_phone: _emberData.default.attr('number'),
    realestate_contact_email: _emberData.default.attr('string'),
    realestate_contact_website: _emberData.default.attr('string'),
    year_built: _emberData.default.attr('string'),
    land_use: _emberData.default.attr('string'),
    owner_contact_name: _emberData.default.attr('string'),
    owner_contact_role: _emberData.default.attr('string'),
    owner_contact_org: _emberData.default.attr('string'),
    owner_contact_phone: _emberData.default.attr('number'),
    owner_contact_email: _emberData.default.attr('string'),
    owner_contact_website: _emberData.default.attr('string'),
    parcel_notes: _emberData.default.attr('string'),
    media_upload: _emberData.default.attr('string'),
    parcel_link1: _emberData.default.attr('string'),
    parcel_link1_description: _emberData.default.attr('string'),
    parcel_link2: _emberData.default.attr('string'),
    parcel_link2_description: _emberData.default.attr('string'),
    internal_parcel_notes: _emberData.default.attr('string'),
    // internal_parcel_media: DS.attr('file'), // need a transform for this
    featured_parcel_photo: _emberData.default.attr('string'),
    message_to_connect: _emberData.default.attr('string'),
    geom: _emberData.default.attr('string'),

    property_for_sale: _emberData.default.attr('timeline'),
    property_for_lease: _emberData.default.attr('timeline'),
    ground_floor_vacancy_status: _emberData.default.attr('timeline'),
    upper_floor_vacancy_status: _emberData.default.attr('timeline'),
    assessed_value: _emberData.default.attr('timeline'),
    site_control: _emberData.default.attr('timeline'),
    engaged_owner: _emberData.default.attr('timeline'),

    timely: Ember.computed.apply(Ember, timelyObservers.concat(['currentCity.timelineDate', function () {
      var _this = this;

      var date = this.get('currentCity.timelineDate');

      return timelyAttributes.reduce(function (attrs, attr) {
        attrs[attr] = (0, _getTimelySnapshot.default)(date, _this.get(attr) || []);
        return attrs;
      }, {});
    }])),

    historical: Ember.computed.apply(Ember, timelyObservers.concat([timelyMembers, function () {
      var _this2 = this;

      var timelines = Ember.Object.create(this.get('timely'));

      Object.keys(timelines).forEach(function (field) {
        var timeline = _this2.get(field);

        if (timeline) {
          timelines[field] = timeline.filter(function (snapshot) {
            return snapshot.date < timelines[field].date;
          });
        }
      });

      return timelines;
    }])),

    // aliases
    is_engaged_owner: alias('timely.engaged_owner.value'),
    assessed_value_d: alias('timely.assessed_value.value'),
    owner_type: alias('timely.site_control.value'),
    ground_floor_vacancy: alias('timely.ground_floor_vacancy_status.value'),
    upper_floor_vacancy: alias('timely.upper_floor_vacancy_status.value'),
    property_for_sale_latest: alias('timely.property_for_sale.value'),
    property_for_lease_latest: alias('timely.property_for_lease.value'),

    tenure_status: Ember.computed('property_for_sale_latest', 'property_for_lease_latest', function () {
      var status = null;

      if (this.get('property_for_lease_latest')) status = 'for_lease';
      if (this.get('property_for_sale_latest')) status = 'for_sale';

      return status;
    }),

    isForSaleOrLease: Ember.computed('tenure_status', function () {
      return this.get('tenure_status');
    }),

    // computeds
    splash: Ember.computed('latitude,longitude', function () {
      var _getProperties = this.getProperties('latitude', 'longitude'),
          latitude = _getProperties.latitude,
          longitude = _getProperties.longitude;

      return 'https://maps.googleapis.com/maps/api/streetview?size=450x300&location=' + latitude + ',' + longitude + '&key=AIzaSyCO654zBIabvjSOV4Ys59Pku8pmzM387ps';
    }),
    latitude: Ember.computed('geojson.geometry', function () {
      var geojson = this.get('geojson');

      return geojson.geometry ? L.geoJSON(geojson).getBounds().getCenter().lat : this.get('city.latitude');
    }),
    longitude: Ember.computed('geojson.geometry', function () {
      var geojson = this.get('geojson');

      return geojson.geometry ? L.geoJSON(geojson).getBounds().getCenter().lng : this.get('city.longitude');
    }),
    geojson: Ember.computed.apply(Ember, geojsonAttributes.concat(['geom', function () {
      var geom = this.get('geom');
      var geojson = Ember.Object.create();

      if (geom) {
        var wkt = new _npmWicket.default.Wkt();
        wkt.read(geom);
        geojson.set('geometry', wkt.toJson());
      }

      geojson.set('type', 'Feature');
      geojson.set('properties', this.getProperties.apply(this, geojsonAttributes));

      return geojson;
    }])),
    city: _emberData.default.belongsTo('city'),

    isSelected: false

  }));


  // dsl for filters
  var PARCEL_FILTERS_CONFIG = exports.PARCEL_FILTERS_CONFIG = [{
    property: 'land_use',
    filter: 'landuseTypesArray',
    filterType: 'isAny'
  }, {
    property: 'ground_floor_vacancy',
    filter: 'GFVacancyStatusesArray',
    filterType: 'isAny'
  }, {
    property: 'upper_floor_vacancy',
    filter: 'UFVacancyStatusesArray',
    filterType: 'isAny'
  }, {
    property: 'owner_type',
    filter: 'ownershipTypesArray',
    filterType: 'isAny'
  }, {
    property: 'tenure_status',
    filter: 'tenureStatusesArray',
    filterType: 'isAny'
  }, {
    property: 'is_engaged_owner',
    filter: 'isEngagedOwner',
    filterType: 'isTrue'
  }];

  // choropleth config
  var PARCEL_MAP_CONFIG = exports.PARCEL_MAP_CONFIG = [{
    setName: 'Available Spaces',
    default_color: 'lightgray',
    colorMap: [{
      key: 'tenure_status',
      label: 'For Sale',
      value: 'for_sale',
      color: '#FCBE78'
    }, {
      key: 'tenure_status',
      label: 'For Lease',
      value: 'for_lease',
      color: '#58BC70'
    }]
  }, {
    setName: 'Ground Floor Vacancy',
    default_color: 'lightgray',
    colorMap: [{
      key: 'ground_floor_vacancy',
      value: 'No Vacancy',
      label: 'Not Vacant',
      color: '#C5D7DC'
    }, {
      key: 'ground_floor_vacancy',
      value: 'Partially Vacant',
      color: '#92A9B6'
    }, {
      key: 'ground_floor_vacancy',
      value: 'Vacant',
      label: 'Fully Vacant',
      color: '#5E768E'
    }, {
      key: 'ground_floor_vacancy',
      value: 'Entirely Vacant Lot',
      label: 'Vacant Lot',
      color: '#C2E2F3'
    }]
  }, {
    setName: 'Land Use',
    default_color: 'lightgray',
    colorMap: [{
      key: 'land_use',
      value: 'Residential',
      color: '#FFC800'
    }, {
      key: 'land_use',
      value: 'Commercial',
      color: '#B40028'
    }, {
      key: 'land_use',
      value: 'Open Space',
      color: '#7AA285'
    }, {
      key: 'land_use',
      value: 'Mixed Use',
      color: '#EB9B68'
    }, {
      key: 'land_use',
      value: 'Institutional',
      color: '#6977C6'
    }, {
      key: 'land_use',
      value: 'Transportation',
      color: '#B47BBE'
    }]
  }, {
    setName: 'Upper Level Vacancy',
    default_color: 'lightgray',
    colorMap: [{
      key: 'upper_floor_vacancy',
      value: 'No Vacancy',
      label: 'Not Vacant',
      color: '#DFDAD2'
    }, {
      key: 'upper_floor_vacancy',
      value: 'Partially Vacant',
      color: '#CBB19E'
    }, {
      key: 'upper_floor_vacancy',
      value: 'Vacant',
      label: 'Fully Vacant',
      color: '#B78570'
    }]
  }, {
    setName: 'Ownership Type',
    default_color: 'lightgray',
    colorMap: [{
      key: 'owner_type',
      value: 'Publicly Owned',
      color: '#C18FB8'
    }, {
      key: 'owner_type',
      value: 'Partnership-Controlled',
      label: 'Partner Controlled',
      color: '#7A6FB3'
    }, {
      key: 'owner_type',
      value: 'Privately Owned',
      color: '#CCD6E5'
    }, {
      key: 'owner_type',
      value: 'MassDevelopment Owned',
      color: '#A289A4'
    }]
  }];

  var PARCEL_OWNERSHIP_TYPES = exports.PARCEL_OWNERSHIP_TYPES = PARCEL_MAP_CONFIG.findBy('setName', 'Ownership Type');

  var PARCEL_TYPES = exports.PARCEL_TYPES = PARCEL_MAP_CONFIG.findBy('setName', 'Land Use');

  var GFVACANCY_STATUSES = exports.GFVACANCY_STATUSES = PARCEL_MAP_CONFIG.findBy('setName', 'Ground Floor Vacancy');

  var UFVACANCY_STATUSES = exports.UFVACANCY_STATUSES = PARCEL_MAP_CONFIG.findBy('setName', 'Upper Level Vacancy');

  var TENURE_STATUSES = exports.TENURE_STATUSES = PARCEL_MAP_CONFIG.findBy('setName', 'Available Spaces');
});