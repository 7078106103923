define('blockxblock/services/prql', ['exports', 'ember-ajax/services/ajax', 'blockxblock/config/environment'], function (exports, _ajax, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    host: _environment.default.hosts.prql,

    request: function request(qs, options) {
      return this._super('?token=' + _environment.default.prqlToken + '&query=' + qs, options);
    }
  });
});