define('blockxblock/components/geojson-to-svg', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    geojson: [],
    didInsertElement: function didInsertElement() {
      var geojson = this.get('geojson');
      var mapW = this.$().height();
      var mapH = this.$().width();
      var projection = d3.geo.mercator().center([-71, 42]).scale(900).rotate([-180, 0]);
      var path = d3.geo.path().projection(projection);

      var elementId = this.get('elementId');
      var svg = d3.select('#' + elementId).append("svg").attr("width", 150).attr("height", 150);

      svg.append("g").selectAll("path").data([geojson]).enter().append("path").attr("d", path);
    }
  });
});