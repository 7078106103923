define("blockxblock/utils/set-choropleth-color", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = setChoroplethColor;
  function setChoroplethColor(feature, choroplethLayer, CONFIG) {
    var color = void 0;
    CONFIG.forEach(function (config) {
      if (config.setName == choroplethLayer) {
        if (config.default_color) {
          color = config.default_color;
        }
        config.colorMap.forEach(function (mapping) {
          if (mapping.value == feature.properties[mapping.key]) {
            color = mapping.color;
          }
        });
      }
    });
    return color;
  }
});