define('blockxblock/serializers/place', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    primaryKey: 'place_id',

    serialize: function serialize(snapshot, options) {
      var investments = (Ember.get(snapshot, '_internalModel.__relationships.initializedRelationships.investments.canonicalState') || []).map(function (x) {
        return parseInt(x.id);
      });
      var serialized = this._super(snapshot, options);

      if (!serialized.investments) {
        serialized.investments = investments.length > 0 ? investments : [];
      }
      if (!serialized.related_places) {
        serialized.related_places = [];
      }

      return serialized;
    }
  });
});