define('blockxblock/utils/validate', ['exports', 'blockxblock/models/city', 'blockxblock/models/place', 'blockxblock/models/parcel', 'blockxblock/models/investment', 'blockxblock/utils/not-empty'], function (exports, _city, _place, _parcel, _investment, _notEmpty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var models = { place: _place.default, parcel: _parcel.default, investment: _investment.default, district: _city.default };

  var validators = {
    string: RegExp('^\.+$', 'i'),
    number: RegExp('^\d+$'),
    text: RegExp('^\.+$', 'mi'),
    email: RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$', 'i'),
    url: RegExp('^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$'),
    phone: RegExp('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$'),
    timeline: {
      test: function test(snapshots) {
        if (snapshots.length > 0) {

          return typeof snapshots[0].value === 'boolean' || snapshots.every(function (snapshot) {
            return (0, _notEmpty.default)(snapshot.value);
          });
        }

        return false;
      }
    }
  };

  function validate(model) {
    var fieldName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var pushInvalid = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

    var _modelName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

    var modelName = _modelName || model.constructor.modelName;

    var modelType = models[modelName];
    var modelAttrs = Object.values(Ember.get(modelType, 'attributes._values')).reduce(function (attrMap, attr) {
      var _extends2;

      return _extends((_extends2 = {}, _extends2[attr.name] = attr.type, _extends2), attrMap);
    }, {});

    var invalidFields = model.get('invalidFields');
    var testableFields = fieldName ? [fieldName] : model.get('requiredFields');
    var testedInvalidFields = [];

    testableFields.forEach(function (field) {
      var validator = validators[modelAttrs[field]] || RegExp('.*');
      var isValid = validator.test(model.get(field));

      if (isValid) {
        invalidFields.removeObject(field);
      } else if (pushInvalid) {
        testedInvalidFields.push(field);
        invalidFields.addObject(field);
      }
    });

    return {
      valid: testedInvalidFields.length === 0,
      invalidFields: testedInvalidFields
    };
  }

  exports.default = validate;
});