define("blockxblock/utils/arrayify", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = arrayify;
  function arrayify(propertyString, delimiter) {
    return {
      get: function get() {
        if (!this.get(propertyString)) return [];
        return this.get(propertyString).split(delimiter);
      }
    };
  }
});