define('blockxblock/helpers/format-phone', ['exports', 'ember-string-helpers/helpers/format-phone'], function (exports, _formatPhone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formatPhone.default;
    }
  });
  Object.defineProperty(exports, 'formatPhone', {
    enumerable: true,
    get: function () {
      return _formatPhone.formatPhone;
    }
  });
});