define('blockxblock/routes/cities/city/new-investment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    templateName: 'cities/city/edit-investment',
    currentCity: Ember.inject.service(),
    model: function model(params) {
      var city = this.modelFor('cities.city');
      var currentCity = this.get('currentCity');

      return this.store.createRecord('investment', {
        city: city.city
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('currentCity', Ember.inject.service('currentCity'));
    },


    actions: {
      didTransition: function didTransition() {
        var currentCity = this.get('currentCity');
        currentCity.set('isPlottingPoint', true);
      },
      willTransition: function willTransition(transition) {
        var currentCity = this.get('currentCity');
        currentCity.set('isPlottingPoint', false);
      },
      submitRoute: function submitRoute(object) {
        var _this = this;

        var currentCity = this.get('currentCity');

        object.setProperties({
          latitude: currentCity.get('newPointLatitude'),
          longitude: currentCity.get('newPointLongitude')
        });

        return object.save().then(function (model) {
          _this.transitionTo('cities.city.investments', model);
        });
      }
    }
  });
});