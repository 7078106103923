define('blockxblock/components/tdi-card', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['component', 'tdi-card'],

    modelContext: null,
    height: 300,
    style: Ember.computed(function () {
      var _getProperties = this.getProperties('height', 'image'),
          height = _getProperties.height,
          image = _getProperties.image;

      return Ember.String.htmlSafe('min-height: ' + height + 'px; background-image: url(\'' + image + '\'); background-size: cover;');
    }),
    route: Ember.computed('modelContext', function () {
      var inflector = new _emberInflector.default(_emberInflector.default.defaultRules);
      return 'cities.city.' + inflector.pluralize(this.get('modelContext').constructor.modelName);
    })
  });
});