define('blockxblock/components/ui-search', ['exports', 'semantic-ui-ember/components/ui-search'], function (exports, _uiSearch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uiSearch.default.extend({
    // refreshSource: Ember.observer('source', function() {
    //   this.set('source', this.get('source'));
    //   this.rerender();
    // })
    classNames: 'ui search',
    didInsertElement: function didInsertElement() {
      this.updateSource();
    },

    updateSource: Ember.observer('source', function () {
      var _this = this;

      Ember.run.schedule('afterRender', this, function () {
        var that = _this;
        $('.ui.search').search({
          source: _this.get('source'),
          onSelect: function onSelect() {
            that.get('onSearchQuery')();
          }
        });
      });
    })
  });
});