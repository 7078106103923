define('blockxblock/components/district-accord', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['component', 'accord', 'district-accord'],

    didInsertElement: function didInsertElement() {
      Ember.run.schedule('afterRender', this, function () {
        this.$('.ui.accordion').accordion();
      });
    }
  });
});