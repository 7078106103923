define('blockxblock/transforms/jsonstring', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        return JSON.parse(serialized);
      } else {
        return [];
      }
    },
    serialize: function serialize(deserialized) {
      if (deserialized) {
        return JSON.stringify(deserialized);
      } else {
        return '[]';
      }
    }
  });
});