define('blockxblock/controllers/cities', ['exports', 'npm:papaparse', 'npm:jszip', 'npm:file-saver', 'blockxblock/utils/csv-factory'], function (exports, _npmPapaparse, _npmJszip, _npmFileSaver, _csvFactory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var service = Ember.inject.service;


  var filterMap = {
    place: 'visibleFeatures',
    investment: 'visibleInvestments',
    parcel: 'visibleParcels'
  };

  exports.default = Ember.Controller.extend({

    currentCity: service(),

    actions: {
      openModal: function openModal(name, feature) {
        $('.ui.' + name + '.modal').modal('show');
        this.set('currentFeature', feature);
      },
      exportCSV: function exportCSV(resources, exceptions) {
        var _this = this;

        var city = this.get('currentCity.city.name');

        Ember.RSVP.allSettled(resources.map(function (resource) {
          return _this.store.query(resource, { city: city });
        })).then(function (results) {
          var formatted = results.map(function (result, i) {
            return {
              name: resources[i],
              raw: (0, _csvFactory.default)(_this.get('currentCity.' + filterMap[result.value.modelName]), exceptions)
            };
          });

          var files = formatted.map(function (dataset) {
            return _extends({}, dataset, {
              data: _npmPapaparse.default.unparse(dataset.raw)
            });
          });

          var now = new Date();
          var archiveName = 'blockxblock-data_' + now.getMonth() + now.getFullYear();

          var zip = new _npmJszip.default();
          var archive = zip.folder(archiveName);
          files.forEach(function (file) {
            return archive.file(file.name + 's.csv', file.data);
          });

          zip.generateAsync({ type: 'blob' }).then(function (blob) {
            _npmFileSaver.default.saveAs(blob, archiveName + '.zip');
            _this.set('isExporting', false);
          });
        });
      }
    }
  });
});