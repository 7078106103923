define('blockxblock/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'blockxblock/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {

    cookies: Ember.inject.service(),

    host: _environment.default.hosts.api,
    namespace: 'api',
    authorizer: 'authorizer:drf-token-authorizer',

    buildURL: function buildURL() {
      var url = this._super.apply(this, arguments);
      return url + '/';
    },
    urlForQuery: function urlForQuery(query) {
      var queryString = Object.keys(query).map(function (key) {
        return key + '=' + query[key];
      }).join('&');
      var safeQueryString = encodeURIComponent(queryString);

      return this.get('host') + '/' + this.get('namespace') + '/' + this.pathForType() + '/?' + safeQueryString;
    }
  });
});