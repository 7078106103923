define('blockxblock/helpers/humanize-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanizeString = humanizeString;
  function humanizeString(params /*, hash*/) {
    return params[0];
  }

  exports.default = Ember.Helper.helper(humanizeString);
});