define('blockxblock/utils/apply-filter-to', ['exports', 'blockxblock/utils/is-any-filter', 'blockxblock/utils/is-true-filter', 'blockxblock/utils/is-within-filter', 'blockxblock/utils/is-timely-filter', 'blockxblock/utils/is-longitudinal-filter'], function (exports, _isAnyFilter, _isTrueFilter, _isWithinFilter, _isTimelyFilter, _isLongitudinalFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = applyFilterTo;
  exports.getFilter = getFilter;
  function applyFilterTo(enumerable, config) {
    if (enumerable) return {
      get: function get() {
        return getFilter(this, enumerable, config);
      }
    };
  }

  function getFilter(context, enumerable, config) {
    var models = context.get(enumerable);

    if (models) {
      config.forEach(function (propertyConfig) {
        var filter = void 0;
        var filterType = propertyConfig.filterType;


        if (filterType == "isWithin") {
          var _propertyConfig$filte = propertyConfig.filter,
              min = _propertyConfig$filte[0],
              max = _propertyConfig$filte[1];

          filter = [context.get(min), context.get(max)];
        } else {
          filter = context.get(propertyConfig.filter);
        }

        var property = propertyConfig.property;

        models = models.filter(filterFactory(filterType).bind(context, filter, property));
      });
    }

    return models;
  }

  function filterFactory(filterType) {
    switch (filterType) {
      case 'isAny':
        return _isAnyFilter.default;
      case 'isTrue':
        return _isTrueFilter.default;
      case 'isWithin':
        return _isWithinFilter.default;
      case 'isLongitudinal':
        return _isLongitudinalFilter.default;
      case 'isTimely':
        return _isTimelyFilter.default;
    }
  }
});