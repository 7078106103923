define('blockxblock/components/accordion-segment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      Ember.run.schedule('afterRender', this, function () {
        this.$('.ui.accordion').accordion();

        if (this.get('visible')) {
          this.$('.ui.visible-default > .title').trigger('click');
        }
      });
    }
  });
});