define('blockxblock/router', ['exports', 'blockxblock/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('cities', function () {
      this.route('city', { path: '/:city_id' }, function () {
        this.route('show');

        this.route('places', { path: '/places/:place_id' }, function () {
          this.route('edit-place', { path: '/edit' });
        });
        this.route('new-place', { path: '/places/new' });

        this.route('investments', { path: '/investments/:investment_id' }, function () {
          this.route('edit-investment', { path: '/edit' });
        });
        this.route('new-investment', { path: '/investments/new' });

        this.route('parcels', { path: '/parcels/:parcel_id' }, function () {
          this.route('edit-parcel', { path: '/edit' });
        });
      });
    });
  });

  exports.default = Router;
});