define('blockxblock/serializers/investment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var products = {
    'TDI': 'tdi_product',
    'Private': 'private_product',
    'Public': 'public_product',
    'MassDevelopment': 'massdev_product'
  };

  exports.default = _emberData.default.JSONSerializer.extend({
    primaryKey: 'investment_id',

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (Array.isArray(payload)) {
        payload.forEach(applyProduct);
      } else {
        applyProduct(payload);
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    serialize: function serialize(snapshot, options) {
      var serialized = this._super(snapshot, options);

      serialized[products[serialized.source_type]] = serialized.product;
      delete serialized.product;

      if (serialized.use_type === 'Planning or Strategy') {
        serialized.use_type = 'Planning/Strategy';
      }

      if (!serialized.related_places) {
        serialized.related_places = [];
      }
      if (!serialized.related_investments) {
        serialized.related_investments = [];
      }

      return serialized;
    }
  });


  function applyProduct(record) {
    record.product = record[products[record.source_type]];
  }
});