define('blockxblock/components/ui-visibility-sticky', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ui-visibility-sticky'],
    currentCity: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      Ember.run.next(this, function () {
        var _$$visibility;

        this.$().visibility((_$$visibility = {
          type: 'fixed',
          offset: 15,
          context: '#sidebar'
        }, _$$visibility['offset'] = 64, _$$visibility));
      });
    }
  });
});