define('blockxblock/helpers/replace-with', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.replaceWith = replaceWith;
  function replaceWith(params /*, hash*/) {
    return params[0].replace(params[1], params[2]);
  }

  exports.default = Ember.Helper.helper(replaceWith);
});