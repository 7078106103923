define('blockxblock/components/parcel-form', ['exports', 'blockxblock/models/parcel'], function (exports, _parcel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    ownershipTypes: valuesFrom(_parcel.PARCEL_OWNERSHIP_TYPES),
    gfVacancyStatuses: valuesFrom(_parcel.GFVACANCY_STATUSES),
    ufVacancyStatuses: valuesFrom(_parcel.UFVACANCY_STATUSES),
    landUseTypes: valuesFrom(_parcel.PARCEL_TYPES)

  });


  function valuesFrom(set) {
    return set.colorMap.map(function (entry) {
      return entry.value;
    });
  }
});