define('blockxblock/models/abstract/satisfiable', ['exports', 'blockxblock/utils/not-empty', 'blockxblock/utils/remove-item'], function (exports, _notEmpty, _removeItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = SatisfiableModel;
  function SatisfiableModel(fields) {
    var conditions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var relationships = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    var timelyAttributes = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];

    var trackableTimelines = fields.filter(function (field) {
      return timelyAttributes.indexOf(field) !== -1;
    }).map(function (attr) {
      return attr + '.@each.value';
    });

    return {
      requiredFields: Ember.computed.apply(Ember, Object.keys(conditions).concat([function () {
        var _this = this;

        var requiredFields = [].concat(fields);

        Object.keys(conditions).forEach(function (condition) {
          if (!_this.get(condition)) {
            if (Array.isArray(conditions[condition])) {
              conditions[condition].forEach(function (field) {
                requiredFields = (0, _removeItem.default)(field, requiredFields);
              });
            } else {
              requiredFields = (0, _removeItem.default)(conditions[condition], requiredFields);
            }
          }
        });

        return requiredFields;
      }])),

      invalidFields: [],

      hasDirtyTimelines: Ember.computed.apply(Ember, timelyAttributes.map(function (attr) {
        return attr + '.@each.value';
      }).concat(timelyAttributes.map(function (attr) {
        return attr + '.@each.date';
      }), [function () {
        var _this2 = this;

        var timelyState = timelyAttributes.map(function (attr) {
          return JSON.stringify(_this2.get(attr));
        });
        var _storedTimelines = this.get('_storedTimelines');

        var dirty = false;
        if (_storedTimelines) {
          dirty = !timelyState.every(function (attr, i) {
            return attr == _storedTimelines[i];
          });
        } else {
          this.set('_storedTimelines', timelyState);
        }

        return dirty;
      }])),

      hasDirtyRelationships: null, // should be overridden as needed

      satisfied: Ember.computed.apply(Ember, fields.concat(trackableTimelines, ['requiredFields.[]', 'invalidFields.[]', function () {
        var _this3 = this;

        var invalidFields = this.get('invalidFields');

        var satisfied = this.get('requiredFields').every(function (fieldName) {
          var field = _this3.get(fieldName);

          return (0, _notEmpty.default)(field) && invalidFields.indexOf(fieldName) === -1;
        });

        return satisfied;
      }])),

      saveable: Ember.computed('satisfied', 'hasDirtyAttributes', 'hasDirtyTimelines', 'hasDirtyRelationships', function () {
        var dirtyRelationships = this.get('hasDirtyRelationships') || false;
        var dirtyTimelines = this.get('hasDirtyTimelines');
        var dirtyAttributes = this.get('hasDirtyAttributes');
        var satisfiedModel = this.get('satisfied');

        return satisfiedModel && (dirtyRelationships || dirtyTimelines || dirtyAttributes);
      })
    };
  }
});