define('blockxblock/components/investment-form', ['exports', 'blockxblock/models/investment'], function (exports, _investment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    investmentTypes: _investment.INVESTMENT_TYPES,
    investmentStatuses: _investment.INVESTMENT_STATUSES,
    investmentSources: _investment.INVESTMENT_SOURCES,
    privateProductChoices: _investment.PRIVATE_PRODUCT_CHOICES,
    tdiInfluences: _investment.TDI_INFLUENCES

  });
});