define('blockxblock/components/search-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    currentCity: Ember.inject.service(),

    classNames: ['component', 'search-bar'],
    tagName: 'div',
    query: '',

    places: Ember.computed('model', function () {
      return this.get('model').rejectBy('location_type', 'District-wide');
    }),

    inputPlaceholder: Ember.computed('currentCity.city.name', function () {
      var city = this.get('currentCity.city.name');
      var text = 'Search Places';

      return city ? text + ' in ' + city + ' ...' : text + ' ...';
    }),

    results: Ember.computed('query', 'places', function () {
      var places = this.get('places');
      var query = this.get('query');
      var results = [];

      if (query.length > 0) {
        var queryWords = query.toLowerCase().split(' ');

        results = places.filter(function (place) {
          var keywords = place.get('name').toLowerCase().split(' ');

          var matchesKeywords = queryWords.every(function (queryWord) {
            return keywords.any(function (keyword) {
              return keyword.startsWith(queryWord);
            });
          });

          return matchesKeywords;
        }).sortBy(function (place) {
          return place.name;
        });
      }

      return results;
    }),

    actions: {
      showResult: function showResult(result) {
        this.set('query', '');

        this.get('router').transitionTo('cities.city.places', result.get('id'));
      },
      clearQuery: function clearQuery() {
        this.set('query', '');
      }
    }

  });
});