define('blockxblock/helpers/join-strings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.joinStrings = joinStrings;
  function joinStrings(params /*, hash*/) {
    return params.reduce(function (a, b) {
      return a + ' ' + b;
    }, '');
  }

  exports.default = Ember.Helper.helper(joinStrings);
});