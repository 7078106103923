define('blockxblock/components/main-map', ['exports', 'blockxblock/utils/set-choropleth-color', 'blockxblock/models/parcel'], function (exports, _setChoroplethColor, _parcel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentCity: Ember.inject.service(),
    router: Ember.inject.service(),
    classNames: ['component', 'main-map'],

    choroplethLayer: 'Available Spaces',
    parcelsChoroplethMapping: Ember.computed('visibleParcels.[]', 'currentCity.choroplethLayer', function () {
      var _this = this;

      return function (feature) {
        var color = (0, _setChoroplethColor.default)(feature, _this.get('currentCity.choroplethLayer'), _parcel.PARCEL_MAP_CONFIG),
            stroke = true,
            fillOpacity = 0.5,
            strokeOpacity = 1,
            weight = 1;
        return {
          color: color,
          stroke: stroke,
          fillOpacity: fillOpacity,
          strokeOpacity: strokeOpacity,
          weight: weight
        };
      };
    }),
    city: Ember.computed.alias('currentCity.city'),
    maxZoom: 20,
    maxNativeZoom: 18,
    maxClusterRadius: 25,
    disableClusteringAtZoom: 17,

    basemap: 'default',

    visibleFeatures: Ember.computed('currentCity.visibleFeatures.[]', 'currentCity.showPlaces', 'currentCity.showInvestments', function () {
      var _get$getProperties = this.get('currentCity').getProperties('showPlaces', 'showInvestments', 'visibleFeatures'),
          showPlaces = _get$getProperties.showPlaces,
          showInvestments = _get$getProperties.showInvestments,
          visibleFeatures = _get$getProperties.visibleFeatures;

      if (!showPlaces) {
        visibleFeatures = showInvestments ? visibleFeatures.filterBy('investments.length') : [];
      }

      return visibleFeatures;
    }),

    actions: {
      navigateTo: function navigateTo(_ref) {
        var originalEvent = _ref.originalEvent;
        var _originalEvent$target = originalEvent.target.dataset,
            linkto = _originalEvent$target.linkto,
            id = _originalEvent$target.id;


        this.get('router').transitionTo(linkto, id);
      },
      updateNewPoint: function updateNewPoint(map) {
        var currentCity = this.get('currentCity');

        if (currentCity.get('isPlottingPoint')) {
          var center = map.target.getCenter();

          Ember.run.next(this, function () {
            currentCity.setProperties({
              'newPointLatitude': center.lat,
              'newPointLongitude': center.lng
            });
          });
        }
      },
      currentMapState: function currentMapState(map) {
        var _this2 = this;

        var center = map.target.getCenter();
        var zoom = map.target.getZoom();
        var layerPoint = map.target.project(center).divideBy(256).floor();
        var currentCity = this.get('currentCity');

        Ember.run.next(this, function () {
          _this2.setProperties({
            layerPointx: layerPoint.x,
            layerPointy: layerPoint.y,
            layerPointz: zoom
          });

          currentCity.setProperties({
            'selectedPointLatitude': currentCity.newPointLatitude,
            'selectedPointLongitude': currentCity.newPointLongitude
          });
        });
      },
      initMap: function initMap(event) {
        var map = event.target;
        var currentCity = this.get('currentCity');
        this.set('mapInstance', map);

        map.zoomControl.setPosition('topright');

        map.createPane('points');
        map.getPane('points').style.zIndex = 325;

        map.createPane('extrusions');
        map.getPane('extrusions').style.zIndex = 950;
        map.getPane('extrusions').style.pointerEvents = 'none';

        map.createPane('parcels');
        map.getPane('parcels').style.zIndex = 375;

        currentCity.set('mapInstance', map);
      }
    }
  });
});