define('blockxblock/components/edit-resource', ['exports', 'ember-inflector', 'blockxblock/models/parcel', 'blockxblock/models/place', 'blockxblock/models/investment'], function (exports, _emberInflector, _parcel, _place, _investment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['component', 'edit-resource'],

    submitRoute: 'submit',
    investmentTypes: _investment.INVESTMENT_TYPES,
    investmentSources: _investment.INVESTMENT_SOURCES,
    assetTypes: _place.FEATURE_TYPES,
    parcelOwnershipTypes: _parcel.PARCEL_OWNERSHIP_TYPES,
    assetSubTypes: Ember.computed(function () {
      return Ember.Object.create(_place.FEATURE_SUBTYPES);
    }),
    currentCity: Ember.inject.service('currentCity'),
    modelName: Ember.computed('model', function () {
      var inflector = new _emberInflector.default(_emberInflector.default.defaultRules);
      return inflector.pluralize(this.get('model').constructor.modelName);
    }),
    actions: {
      submit: function submit(object) {
        var _this = this;

        var currentCity = this.get('currentCity');

        object.setProperties({
          latitude: currentCity.get('newPointLatitude'),
          longitude: currentCity.get('newPointLongitude')
        });

        return object.save().then(function (model) {
          _this.get('router').transitionTo('cities.city.' + _this.get('modelName'), model);
        });
      }
    }
  });
});