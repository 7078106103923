define('blockxblock/controllers/cities/city/show', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;

  var money = function money(num) {
    return (0, _formatMoney.default)(num, '$', 0);
  };

  exports.default = Ember.Controller.extend({
    queryParams: ['currentCity.showPlaces', 'currentCity.showInvestments', 'currentCity.showParcels'],

    currentCity: Ember.inject.service(),
    session: Ember.inject.service(),

    tooltipsConfig: [{ to: money }, { to: money }],

    investmentsValues: computed.mapBy('currentCity.city.investments', 'estimated_amount'),
    maxInvestments: computed.max('investmentsValues', 'currentCity.city.investments'),
    minInvestments: computed.min('investmentsValues', 'currentCity.city.investments'),

    investmentValueRange: computed('maxInvestments', 'minInvestments', function () {
      return {
        min: this.get('minInvestments'),
        max: this.get('maxInvestments')
      };
    }),

    actions: {
      composeList: function composeList(option, optionsList) {
        var list = this.get(optionsList).split('|');
        if (list.includes(option)) {
          list.removeObject(option);
        } else {
          list.pushObject(option);
        }
        this.set(optionsList, list.join('|'));
      },
      updateRanges: function updateRanges(_ref) {
        var min = _ref[0],
            max = _ref[1];

        this.set('currentCity.valueMin', min);
        this.set('currentCity.valueMax', max);
      },
      openModal: function openModal(name, feature) {
        $('.ui.' + name + '.modal').modal('show');
        this.set('currentFeature', feature);
      },
      linkTo: function linkTo(route, model) {
        this.transitionToRoute(route, model);
      },
      changeProperty: function changeProperty(key, value) {
        this.set(key, value);
      },
      updateDate: function updateDate(date) {
        this.set('fake_open_or_closed', new Date(date));
        this.set('investments_fake_open_or_closed', new Date(date));
      },
      updateSliderDate: function updateSliderDate(val) {
        this.set('fake_open_or_closed', val);
      },
      export_csv: function export_csv(resource, exceptions) {
        var resources = csvFactory(this.get(resource), exceptions);
        this.get('csv').export(resources, { fileName: resource + '.csv' });
      }
    }
  });
});