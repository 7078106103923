define("blockxblock/utils/get-latest", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getLatest;
  function getLatest(prop, context) {
    var property = context.get(prop);
    if (property) {
      property = JSON.parse(property);
      return property.sortBy(function (el) {
        return moment(el.date);
      })[property.length - 1].status;
    } else {
      return [];
    }
  }
});