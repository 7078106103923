define('blockxblock/components/abstract/required-field', ['exports', 'blockxblock/utils/validate'], function (exports, _validate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component,
      get = Ember.get,
      observer = Ember.observer;
  exports.default = Component.extend({

    hasInteracted: false,
    debounce: 800,

    init: function init() {
      this._super();

      if (this.get('model')) {
        Ember.defineProperty(this, 'value', Ember.computed.alias('model.' + this.get('field')));
      }
    },


    required: computed('model.requiredFields.[]', 'field', function () {
      return this.listContainsField('requiredFields');
    }),

    invalid: computed('model.invalidFields.[]', 'field', function () {
      return this.listContainsField('invalidFields');
    }),

    /*
    valueChanged: observer('value', function() {
      if (this.get('value.length') > 0) this.set('hasInteracted', true);
       if (this.get('required') && this.get('hasInteracted')) {
        clearTimeout(this.get('validationDebounce'));
         this.set('validationDebounce',  setTimeout(() => {
          validate(this.get('model'), this.get('field'));
        }, this.get('debounce')));
      }
    }),
    */

    listContainsField: function listContainsField(fieldListName) {
      var model = this.get('model');
      if (model) {
        var fieldList = get(model, fieldListName) || [];
        return fieldList.length && fieldList.indexOf(this.get('field')) !== -1;
      }

      return false;
    }
  });
});