define('blockxblock/authorizers/drf-token-authorizer', ['exports', 'ember-simple-auth/authorizers/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    session: Ember.inject.service(),

    authorize: function authorize(sessionData, block) {
      if (this.get('session.isAuthenticated') && !Ember.isEmpty(sessionData.token)) {
        block('Authorization', 'Token ' + sessionData.token);
      }
    }
  });
});