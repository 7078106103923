define('blockxblock/routes/cities/city', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set;
  var service = Ember.inject.service;
  exports.default = Ember.Route.extend({

    prql: service(),
    currentCity: service(),

    model: function model(params) {
      var cities = this.modelFor('cities');
      var city = cities.findBy('id', params.city_id);

      return Ember.RSVP.hash({
        city: city,
        places: this.store.query('place', {
          city: city.get('name'),
          include: 'id,place_id,status,address,latitude,longitude,place_type,subtype,description,city,investments,employment,activating,community_hub,name,location_type'
        }),
        investments: this.store.query('investment', {
          city: city.get('name'),
          include: 'id,record_url,investment_id,source_type,place_id,city,use_type,investment_status,estimated_amount,exact_amount,amount_is_exact,investment_descriptor,tdi_influence,tdi_product,massdev_product,public_product,private_product'
        }),
        parcels: this.store.query('parcel', {
          city: city.get('name'),
          include: 'id,parcel_id,site_control,upper_floor_vacancy_status,ground_floor_vacancy_status,street_address,year_built,land_use,city,property_for_lease,property_for_sale,engaged_owner'
        })
      });
    },
    afterModel: function afterModel(model) {

      /*
       * Get Well-known Text to generate GeoJSON for parcels. This information
       * is spread across three tables, so we need to fetch more data if not
       * found in any one of the tables.
       */
      var prql = this.get('prql');

      function getParcelShape() {
        var parcels = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        var tables = arguments[1];


        var parcelIds = parcels.map(function (parcel) {
          return (Ember.get(parcel, 'parcel_id') || "").replace('&301-316', '');
        }).filter(function (x) {
          return x;
        }).join(',');
        var sql = 'SELECT mappar_id as id, sde.ST_AsText(sde.ST_Transform(shape, 4326)) as shape FROM mapc.ma_parcels_' + tables.shift() + ' WHERE mappar_id = ANY(\'{' + parcelIds + '}\'::text[])';

        prql.request(sql).then(function (res) {
          if (res.rows) {
            if (res.rows.length !== parcels.length) {
              var foundParcels = res.rows.map(function (row) {
                return row.id;
              });
              var missingParcels = parcels.filter(function (parcel) {
                return foundParcels.indexOf(Ember.get(parcel, 'parcel_id')) === -1;
              });

              getParcelShape(missingParcels, tables);
            }

            var indexedRows = {};
            res.rows.forEach(function (row) {
              indexedRows[row.id] = row.shape;
            });

            parcels.forEach(function (parcel) {
              if (indexedRows[Ember.get(parcel, 'parcel_id')]) {
                Ember.set(parcel, 'geom', indexedRows[Ember.get(parcel, 'parcel_id')]);
              }
            });
          } else if (tables.length > 0) {
            getParcelShape(parcels, tables);
          }
        }).catch(function () {
          if (tables.length > 0) {
            getParcelShape(parcels, tables);
          }
        });
      }

      getParcelShape(model.parcels, ['west', 'metrofuture', 'southeast']);

      /*
       * Find places that have identical lat/lng
       */
      var locations = {};
      var radius = .00005;

      model.places.forEach(function (place) {
        var location = place.get('latitude') + '-' + place.get('longitude');
        locations[location] = locations[location] || [];
        locations[location].push(place);
      });

      // Displace these locations by a small amount so they don't entirely overlap
      Object.values(locations).filter(function (location) {
        return location.length > 1;
      }).forEach(function (location) {
        location.forEach(function (place, i) {
          var radian = i * (Math.PI / ((location.length - 1) / 2));
          var adjustedLat = place.get('latitude') + Math.sin(radian) * radius;
          var adjustedLng = place.get('longitude') + Math.cos(radian) * radius;

          place.set('latitude', adjustedLat);
          place.set('longitude', adjustedLng);
        });
      });

      var currentCity = this.get('currentCity');
      currentCity.setCity(model.city);
      currentCity.setAllInvestments(model.investments.toArray());
    }
  });
});