define('blockxblock/components/investment-accord', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  exports.default = Ember.Component.extend({
    classNames: ['component', 'accord', 'investment-accord'],

    sourceType: alias('model.source_type'),
    investmentType: alias('model.investment_type'),
    estAmount: alias('model.estimated_amount'),
    investmentStatus: alias('model.investment_status_val'),
    exactAmount: alias('model.exact_amount'),
    contactType: alias('model.contact_type'),
    contactName: alias('model.inv_contact_name'),
    contactRole: alias('model.inv_contact_role'),
    contactOrg: alias('model.inv_contact_org'),
    historical: alias('model.historical'),

    didInsertElement: function didInsertElement() {
      Ember.run.schedule('afterRender', this, function () {
        this.$('.ui.accordion').accordion();
      });
    }
  });
});